import React, { useEffect, useState } from "react";
import { View, Button, Text, StyleSheet, FlatList, ScrollView, TouchableOpacity} from "react-native";
import styles from '../../globalStyles/userTypeFrontPageStyle';
import Axios from "axios";
import Authorization from "../../backend/authorization";
export default function ShopFrontPage({navigation}){
    const getData = () => {

    }
    return(
        <View style={styles.container}>
            <View style={styles.choiseLine}>
                <TouchableOpacity style={styles.choiseButton} onPress={() => navigation.navigate('OrderFlowerPage')}>
                    <Text>Tilføj-</Text>
                    <Text>blomst</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.choiseButton} onPress={() => navigation.navigate('ShopFlowerOverview')}>
                    <Text>Blomster-</Text>
                    <Text>oversigt</Text>
                </TouchableOpacity>
            </View>
            <View style={styles.choiseLine}>
                <TouchableOpacity style={styles.choiseButton} onPress={() => navigation.navigate('SquareGoodsPage')}>
                    <Text>Torvevarer</Text>
                </TouchableOpacity>
            </View>

        </View>
    )
}
