import React, {useEffect, useRef, useState} from 'react';
import {StyleSheet, View, Text, Button, TextInput, Platform, TouchableOpacity, FlatList} from 'react-native';
import Axios from 'axios';
import DatePicker from "../components/datePicker";
import Authorization from "../backend/authorization";
import {ipaddress} from "../backend/backendConnection";


export default function FlowerEditor({navigation}){
    const [oldFlower, setOldFlower] = useState({});
    const [newFlower, setNewFlower] = useState({});
    const [price, setPrice] = useState("");
    const d = new Date();
    const [date, setDate] = useState({day: d.getDate(), month: d.getMonth() +1 , year: d.getFullYear()})
    const [pickUpDate, setPickUpDate] = useState({day: d.getDate(), month: d.getMonth() +1 , year: d.getFullYear()})
    const [time, setTime] = useState({hour: "0", min: "0"})
    const [response, setResponse] = useState("")
    useEffect(() => {
        let flower = navigation.state.params.item;
        if(flower.count !== null && flower.count !== undefined)
            flower = {...flower, count: flower.count.toString()}
        setOldFlower(flower);
        setNewFlower(flower);
        const d = new Date(flower.deliveryDate);
        setDate({day: d.getDate(), month: d.getMonth() +1 , year: d.getFullYear()})
        const dd = new Date(flower.collectionDate);
        setPickUpDate({day: dd.getDate(), month: dd.getMonth() +1 , year: dd.getFullYear()})
        const h = flower.deliveryTime.substr(3,2);
        const m = flower.deliveryTime.substr(6);
        setTime({hour: h, min: m})
        setPrice(flower.price.toString())

        console.log(newFlower)

    }, [])
    const updateFlower = () => {
        console.log("updating")
        navigation.state.params.reloadFunction();
        setOldFlower({...newFlower, price: price, time: "00:"+time.hour+":"+time.min, deliveryDate: date, collectionDate: pickUpDate});
    }
    const updateItem = () => {
        Axios.post("https://"+ipaddress+"/updateFlower", {
                oldFlower: {...oldFlower},
                newFlower: {...newFlower, price: price, time: "00:"+time.hour+":"+time.min, deliveryDate: date, collectionDate: pickUpDate}
            },
            {
                headers: {
                    Authorization: Authorization.authdetails.authkey
                }
            }).then(r => {if(r.data === true){ setResponse("Leveringen er opdateret"); updateFlower()}}).catch(err => console.log(err))
    }
    const updatePrice = () => {
        console.log(newFlower, "CC")
        Axios.post("https://"+ipaddress+"/calculateDeliveryPrice", {
                shop: newFlower.shop,
                zip: newFlower.zip,
                church: newFlower.churchDelivery === 1,
                special: !(newFlower.description === null || newFlower.description === undefined),
                count: newFlower.count
            },
            {
                headers: {
                    Authorization: Authorization.authdetails.authkey
                }
            }).then(r => {console.log(";DSDSSDSDSDDSDS",r.data); if(r.data){ setPrice(r.data.toString())}}).catch(err => console.log(err))
    }
    const ChurchDelivery = () => {
        return(
            <View style={localStyle.container}>
                <View style={localStyle.inputRow}>
                    <Text>Kirke levering:</Text>
                    <View style={{flexDirection: "row"}}>
                        <Button title={"Ja"} onPress={() => {setNewFlower({...newFlower, churchDelivery: 1});}} color={newFlower.churchDelivery === 1 ? "":"#A6D4F3"}/>
                        <Button title={"Nej"} onPress={() => {setNewFlower({...newFlower, churchDelivery: 0});}} color={newFlower.churchDelivery === 0 ? "":"#A6D4F3"}/>

                    </View>

                </View>
                { newFlower.churchDelivery === 0 ? null :
                    <View style={{width:"100%"}}>
                        <View style={localStyle.inputRow}>

                            <Text>Kirkens navn</Text>
                             <TextInput
                                   style={localStyle.inputField}
                                   value={newFlower.churchName}
                                   onChangeText={text => setNewFlower({...newFlower, churchName: text})}/>
                        </View>
                        <View style={localStyle.inputRow}>

                            <Text>Tidspunkt: </Text>
                            <View style={{flexDirection: "row"}}>
                                <TextInput value={time.hour}
                                           style={localStyle.timeField}
                                           onChangeText={text => {setTime({...time, hour: text}); setNewFlower({...newFlower, deliveryTime:time.hour+":"+time.min+":"+"00"})}}/>
                                <Text>:</Text>
                                <TextInput value={time.min}
                                           style={localStyle.timeField}
                                           onChangeText={text => {setTime({...time, min: text}); setNewFlower({...newFlower, deliveryTime:time.hour+":"+time.min+":"+"00"})}}/>
                            </View>
                        </View>
                        <View style={localStyle.inputRow}>
                            <Text>Hvor skal blomsten samles op?</Text>
                            <Button
                                color={newFlower.pickedUpFromShop ? "":"#A6D4F3"}
                                title= "Butikken"
                                onPress={() => setNewFlower({...newFlower, pickedUpFromShop: 1})}
                            />
                            <Button
                                color={!newFlower.pickedUpFromShop ?  "":"#A6D4F3"}
                                title="Torvet"
                                onPress={() => setNewFlower({...newFlower, pickedUpFromShop: 0})}
                            />
                        </View>
                    </View>
                }
            </View>
        )
    }

    const RenderDescriptionBox = () => {
        return (
                <TextInput multiline
                           style={{aspectRatio: 3, borderWidth: 1, width: "90%", borderRadius: 5}}
                           value={newFlower.description}
                           onChangeText={text => setNewFlower({...newFlower, description: text})}
                />

            )
    }
    const SpecialDelivery = () => {
        return (
            <View style={localStyle.container}>
                <View style={localStyle.inputRow}>
                    <Text>Special levering:</Text>
                    <View style={{flexDirection: "row"}}>
                        <Button title={"Ja"} onPress={() => {setNewFlower({...newFlower, description:""})}} color={newFlower.description !== null ? "":"#A6D4F3"}/>
                        <Button title={"Nej"} onPress={() => {setNewFlower({...newFlower, description:null})}} color={newFlower.description === null ? "":"#A6D4F3"}/>
                    </View>
                </View>
            </View>
        )
    }
    useEffect(()=>{
            updatePrice();
    }, [newFlower.description, newFlower.churchDelivery, newFlower.zip, newFlower.count])
    return(
        <View style={localStyle.container}>
            <View style={{width: Platform.OS === "web" ? "25%" : "100%"}}>
                {
                    ChurchDelivery()
                }
                <View style={localStyle.inputRow}>
                    <Text>Vejnavn:</Text>
                    <TextInput value={newFlower.street}  style={localStyle.inputField} onChangeText={(text) => setNewFlower({...newFlower, street: text})}/>
                </View>
                <View style={localStyle.inputRow}>
                    <Text>Nr og lejlighed</Text>
                    <TextInput value={newFlower.number}  style={[localStyle.inputField]} maxLength={20}
                               onChangeText={(text) => {setNewFlower({...newFlower, number: text})}}/>
                </View>
                <View style={localStyle.inputRow}>
                    <Text>Post. nr:</Text>
                    <TextInput value={newFlower.zip}  style={[localStyle.inputField, {width: 40}]} maxLength={4}
                               onChangeText={(text) => {setNewFlower({...newFlower, zip: text}); updatePrice()}}/>
                </View>
                <View style={localStyle.inputRow}>
                    <Text>By:</Text>
                    <TextInput value={newFlower.city}  style={localStyle.inputField} onChangeText={(text) => setNewFlower({...newFlower, city: text})}/>
                </View>
                <View style={localStyle.inputRow}>
                    <Text>Modtager:</Text>
                    <TextInput value={newFlower.receiver}  style={localStyle.inputField} onChangeText={(text) => setNewFlower({...newFlower, receiver: text})}/>
                </View>
                <View style={localStyle.inputRow}>
                    <Text>Modtager tlf:</Text>
                    <TextInput value={newFlower.phone} maxLength={8} style={localStyle.inputField} onChangeText={(text) => setNewFlower({...newFlower, phone: text})}/>
                </View>
                <View style={localStyle.inputRow}>
                    <Text>Antal:</Text>
                    <TextInput value={newFlower.count}  style={localStyle.inputField}
                               onChangeText={(text) => {setNewFlower({...newFlower, count: text}); }}/>
                </View>
                {   new Date(oldFlower.deliveryDate) > d ?
                    <View style={{width: "100%"}}>
                        <View style={localStyle.inputRow}>
                            <Text>Levering:</Text>
                            <DatePicker source={date} setSource={setDate}/>
                        </View>
                        <View style={localStyle.inputRow}>
                            <Text>Afhentning:</Text>
                            <DatePicker source={pickUpDate} setSource={setPickUpDate}/>
                        </View>
                    </View>

                    : null
                }
                <SpecialDelivery/>
                <View style={{width: "100%", alignItems: "center"}}>
                    {newFlower.description !== null ? RenderDescriptionBox(): null}
                </View>
                {
                    Authorization.authdetails.referencinguser === "Admin" ?
                        <View style={localStyle.inputRow}>
                            <Text>Pris</Text>
                            <TextInput value={price} style={[localStyle.inputField, {width:"20%"}]} onChangeText={(text) => {console.log("SETTING NEW PRICEINF"+text+" FDS"); setPrice(text)}}/>
                        </View>
                        : <View style={localStyle.inputRow}>
                            <Text>Pris: {price} kr.</Text>
                        </View>
                }
                <Button title={"Bekræft ændringer"} onPress={() => updateItem()}/>
                <Text>{response}</Text>
            </View>
        </View>
    )
}

const localStyle = StyleSheet.create({
    container: {
        alignItems: "center",

    },
    inputRow: {
        flexDirection: "row",
        width:"100%",
        justifyContent: "space-between",
        paddingHorizontal: 10,
        alignContent: "center",
        marginVertical: 3
    },
    inputField: {
        width:"40%",
        borderWidth: 1,
        borderRadius: 5
    },
    timeField: {
        borderWidth: 1,
        borderRadius: 5,
        width:25
    }
})
