import React, {Component, useEffect, useState} from "react";
import {
    View,
    FlatList,
    Text,
    TextInput,
    Button,
    StyleSheet,
    TouchableOpacity,
    BackHandler,
    SectionList
} from "react-native";
import {AntDesign, MaterialIcons} from "@expo/vector-icons";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";

export default function CyclicalPicker({title, value, setValue, maxVal, minVal}){
    const pressHandler = (newValue) => {
        if(newValue > maxVal)
            setValue(minVal)
        else if (newValue < minVal)
            setValue(maxVal)
        else
            setValue(newValue)

    }
    return(
        <View style={style.container}>
            <TouchableOpacity onPress={() => pressHandler(value-1)}>
                <MaterialIcons name={'chevron-left'} size={30}/>
            </TouchableOpacity>
            <Text style={{fontSize: 20}}>{title}: {value}</Text>
            <TouchableOpacity onPress={() => pressHandler(value+1)}>
                <MaterialIcons name={'chevron-right'} size={30}/>
            </TouchableOpacity>
        </View>
    )
}

const style = StyleSheet.create({
    container:{
        width: "100%",
        justifyContent: "center",
        flexDirection: "row",
        alignItems: "center"
    }
})
