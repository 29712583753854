import { createStackNavigator } from 'react-navigation-stack';
import { createAppContainer } from 'react-navigation';
import ChooseUserType from '../pages/chooseUserType';
import Login from '../pages/login';
import AdminFrontPage from '../pages/admin/adminFrontPage';
import FlowerOverview from '../pages/admin/flowerOverview';
import DriverOverview from '../pages/admin/driverOverview';
import DriverWorkingDaysOverview from '../pages/admin/driverWorkingDaysOverview';
import ShopOverview from '../pages/admin/shopOverview';
import ShopFrontPage from '../pages/shop/ShopFrontPage';
import OrderFlowerPage from '../pages/shop/OrderFlowerPage';
import ShopFlowerOverview from "../pages/shop/ShopFlowerOverview";
import SquareGoodsPage from "../pages/shop/SquareGoodsPage";
import RouteControlPage from "../pages/admin/RouteControlPage";
import RouteDriverConfiguration from "../pages/admin/routeDriverConfiguration";
import DriverFrontPage from "../pages/driver/driverFrontPage";
import MorningRoutePage from "../pages/driver/morningRoutePage";
import ModifyMorningRoutes from "../pages/driver/ModifyMorningRoutes";
import AfternoonRoutePage from "../pages/driver/afternoonRoutePage";
import FlowerEditor from "../pages/flowerEditor";
import SignUp from "../pages/SignUp";
import WorkingDays from "../pages/driver/WorkingDays";
import PricingPage from "../pages/admin/PricingPage";
import AfternoonNavigationPage from "../pages/driver/afternoonNavigationPage";
import SquareGoodsViewAndOrder from "../pages/admin/squareGoodsViewAndOrder";
import MorningRoutePlanner from "../pages/admin/morningRoutePlanner";
import MorningNavigationPage from "../pages/driver/morningNavigationPage";
const screens = {
  ChooseUserType: {
    screen: ChooseUserType,

  },
  Login: {
    screen: Login,
  },
  AdminFrontPage: {
    screen: AdminFrontPage
  },
  FlowerOverview: {
    screen: FlowerOverview
  },
  DriverOverview:{
    screen: DriverOverview
  },
  DriverWorkingDaysOverview:{
    screen: DriverWorkingDaysOverview
  },
  ShopOverview: {
    screen: ShopOverview
  },
  ShopFrontPage: {
    screen: ShopFrontPage
  },
  OrderFlowerPage: {
    screen: OrderFlowerPage
  },
  ShopFlowerOverview: {
    screen: ShopFlowerOverview
  },
  SquareGoodsPage: {
    screen: SquareGoodsPage
  },
  Routes: {
    screen: RouteControlPage
  },
  RouteDriverConfiguration: {
    screen: RouteDriverConfiguration
  },
  DriverFrontPage: {
    screen: DriverFrontPage
  },
  MorningRoutePage: {
    screen: MorningRoutePage
  },
  ModifyMorningRoutes: {
      screen: ModifyMorningRoutes
  },
  AfternoonRoutePage:{
    screen: AfternoonRoutePage
  },
  FlowerEditor: {
    screen: FlowerEditor
  },
  SignUp: {
    screen: SignUp
  },
  WorkingDays: {
    screen: WorkingDays
  },
  PricingPage: {
    screen: PricingPage
  },
  AfternoonNavigationPage: {
    screen: AfternoonNavigationPage
  },
  SquareGoodsViewAndOrder: {
    screen: SquareGoodsViewAndOrder
  },
  MorningRoutePlanner: {
    screen: MorningRoutePlanner
  },
  MorningNavigationPage: {
    screen: MorningNavigationPage
  }

};

// home stack navigator screens
const loginStack = createStackNavigator(screens);

export default createAppContainer(loginStack);
