import React, {useState} from 'react';
import { StyleSheet, View, Text, Button, TextInput, SafeAreaView, SectionList,FlatList } from 'react-native';
import {MaterialIcons} from '@expo/vector-icons';
import { TouchableOpacity } from 'react-native';
import {AntDesign} from '@expo/vector-icons';
import Axios from "axios";
import {ipaddress} from "../backend/backendConnection";
import Authorization from "../backend/authorization";

export default function DeleteButton({deleteFunction, item}){
    const [pressed, setPressed] = useState(false)
    return(
        <View>
            <TouchableOpacity>
                {   !pressed ?
                    <MaterialIcons name='delete' size={30} onPress={() => setPressed(true)}/>
                    :
                    <View style={{flexDirection: "column"}}>
                        <MaterialIcons  name='thumb-up' size={25} onPress={() => deleteFunction(item)}/>
                        <MaterialIcons name={'thumb-down'} style={{marginTop: 15}} size={25} onPress={() => setPressed(false)}/>
                    </View>
                }
            </TouchableOpacity>
        </View>
    )
}
