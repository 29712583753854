import React, {useEffect, useState} from 'react';
import {StyleSheet, View, Text, Button, TextInput, Platform, TouchableOpacity, FlatList} from 'react-native';
import CryptoJS from 'crypto-js';
import Axios from 'axios';
import styles from '../../globalStyles/userTypeFrontPageStyle';
import DatePicker from "../../components/datePicker";
import {ipaddress} from "../../backend/backendConnection";
import Authorization from "../../backend/authorization";
import {changeDeliveryState} from "../../backend/datafunctions";
import {MaterialIcons} from "@expo/vector-icons";

export default function MorningRoutePage({navigation}){

    const d = new Date()
    const [date, setDate] = useState({day: d.getDate(), month: d.getMonth()+1, year: d.getFullYear()})
    const [shops, setShops] = useState([])
    const [squareGoods, setSquareGoods] = useState([])
    const [churchFlowers, setChurchFlowers] = useState([])

    const loadChurchFlowers = () => {
        Axios.post("https://"+ipaddress+"/getChurchFlowersForMorningRoute", {
                driver: Authorization.authdetails.referencinguser,
                date: date
            },
            {
                headers: {
                    Authorization: Authorization.authdetails.authkey
                }
            }).then(r => {setChurchFlowers(r.data)})
    }
    useEffect(() => {
        setSquareGoods([])
        Axios.post("https://"+ipaddress+"/getDriversShopsOnRoute", {
                shop: Authorization.authdetails.referencinguser,
                date: date
            },
            {
                headers: {
                    Authorization: Authorization.authdetails.authkey
                }
            }).then(r => {if(r.data !== false){
                setShops(r.data)
            Axios.post("https://"+ipaddress+"/getSquareGoodsForShops", {
                    date: date,
                    driver: Authorization.authdetails.referencinguser
                },
                {
                    headers: {
                        Authorization: Authorization.authdetails.authkey
                    }
                }).then(r => {console.log(r.data);if(r.data !== false){setSquareGoods(r.data)}})
            }})

        loadChurchFlowers()
    }, [date])

    const Shop = ({item}) => {
        const address = item.street+", "+item.number+", "+item.zip+", "+item.city;
        return(
            <TouchableOpacity style={localStyle.shopEntry} onPress={() => {navigation.navigate('ModifyMorningRoutes', {
                shop: item.shop,
                date: date,
            })}}>
                <Text style={localStyle.shopViewColumn}>{item.shop}</Text>
                <Text style={localStyle.shopViewColumn}>{address}</Text>
                <Text style={localStyle.shopViewColumn}>{item.flowerCount}</Text>
            </TouchableOpacity>
        )
    }

    const ShopListHeader = () => {
        return(
            <View style={{flexDirection:"row", backgroundColor: "white"}}>
                <Text style={localStyle.shopViewColumn}>Butik:</Text>
                <Text style={localStyle.shopViewColumn}>Adresse:</Text>
                <Text style={localStyle.shopViewColumn}>Antal blomster: </Text>
            </View>
        )
    }

    const renderSquareGood = (item) => {
        return(<Text>{item.shop}</Text>)
    }

    const SquareGoodHeader = () => {
        return(
            <View style={localStyle.headerWithTitle}>
                <Text>Torvevarer</Text>
                <View style={{alignItems: "flex-start", width: "100%"}}>
                    <Text>Butik:</Text>
                </View>
            </View>
        )
    }

    const ChurchFlower = ({item}) => {
        let delButton = null;
        let newState = 'H';
        switch (item.delivered){
            case 'H':
                delButton = 'delivery-dining'
                newState = 'P'
                break
            case 'R':
                delButton = 'arrow-back'
                newState = 'H'
                break
            case 'P':
                delButton = 'person';
                newState = 'D'
                break
            case 'D':
                delButton = 'sensor-door';
                newState = 'R'
                break
        }
        return(
            <View style={localStyle.shopEntry}>
                <Text style={{width: "40%"}}>{item.shop+"/"+item.churchName}</Text>
                <Text style={{width: "16%"}}>{item.deliveryTime.substr(3)}</Text>
                <Text style={{width: "16%"}}>{item.pickedUpFromShop === 1 ? "Butik" : "Torvet"}</Text>
                <Text style={{width: "16%"}}>{item.afternoonChurchDelivery === 0 ? "Ja" : "Nej"}</Text>
                { item.afternoonChurchDelivery === 0 ?
                    <TouchableOpacity style={{width: "16%"}}
                                      onPress={() => changeDeliveryState(item, newState, loadChurchFlowers)}>
                        <MaterialIcons name={delButton} size={30}/>
                    </TouchableOpacity> :
                    null
                }
            </View>
        )
    }

    const ChurchFlowerHeader = () => {
        return(
        <View style={{flexDirection:"row", backgroundColor: "white"}}>
            <Text style={{width: "40%"}}>Butik/kirke</Text>
            <Text style={{width: "16.6%"}}>Seneste lev.</Text>
            <Text style={{width: "16.6%"}}>Opsamling</Text>
            <Text style={{width: "16.6%"}}>Morgen</Text>
            <Text style={{width: "10%"}}>Lev</Text>
        </View>
        )
    }
    return(
        <View style={localStyle.container}>
            <Text style={styles.title}>Morgenrute</Text>
            <DatePicker source={date} setSource={setDate}/>
            <FlatList style={localStyle.listStyle}
                      data={shops} ListHeaderComponent={() => <ShopListHeader/>} renderItem={({item}) => <Shop item={item}/>}/>
            <FlatList data={squareGoods}
                      style={localStyle.squareGoodsList}
                      ListHeaderComponent={<SquareGoodHeader/>}
                      renderItem={({item}) => renderSquareGood(item)}/>
            <FlatList data={churchFlowers}
                      style={{width:"100%", aspectRatio: 4}}
                      ListHeaderComponent={<ChurchFlowerHeader/>}
                      renderItem={({item}) => <ChurchFlower item = {item}/>}/>

        </View>
    )
}

const localStyle = StyleSheet.create({
    shopEntry:{
        flexDirection: "row"
    },
    shopViewColumn: {
        width: "33%"
    },
    listStyle: {
        width: "100%",
        aspectRatio: 7/4,
    },
    container: {
        alignItems: "center"
    },
    headerWithTitle: {
        alignItems: "center",
        marginTop: 7,
        backgroundColor: "white",
    },
    squareGoodsList: {
        width: "100%",
        aspectRatio: 3
    }

})
