import React, {Component, useState} from "react";
import { View, FlatList,Text, TextInput, Button, StyleSheet, TouchableOpacity, Alert } from "react-native";
import styles from '../../globalStyles/userTypeFrontPageStyle';

export default function TouchableDriverEntry({pressHandler, data, activeDriver}){

    return(
        <View style={ activeDriver !== undefined && activeDriver === data.DriverID ? localStyle.clickedNameLine : localStyle.nameLine}>
            <TouchableOpacity onPress={() => {pressHandler(data.DriverID)}}>
                <Text style={styles.choiseLine}>{data.DriverName}</Text>
            </TouchableOpacity>
        </View>
    )
}
const localStyle = StyleSheet.create({
    nameLine:{
        margin:5,
        flexDirection: 'row',
        borderWidth:1,
        borderStyle: "dashed"
    },
    clickedNameLine:{
        margin:5,
        flexDirection: 'row',
        borderWidth:1,
        borderStyle: "dashed",
        backgroundColor: "#18fd6a"
    }


})
