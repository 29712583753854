import React, {Component, useEffect, useState} from "react";
import {
    View,
    FlatList,
    Text,
    TextInput,
    Button,
    StyleSheet,
    TouchableOpacity,
    BackHandler,
    ScrollView
} from "react-native";
import styles from '../../globalStyles/userTypeFrontPageStyle';
import Axios from "axios";
import Authorization from "../../backend/authorization";
import FlowerView from "../../components/flowerView";
import {ipaddress} from "../../backend/backendConnection";
import DatePicker from "../../components/datePicker";
import globalStyles from "../../globalStyles/newGlobalStyles";
import {MaterialIcons} from "@expo/vector-icons";
import {getShops, getShopsForDropDown, getShopsForDropDownWithOutAll} from "../../backend/datafunctions";
import DropDownPicker from "react-native-dropdown-picker";

export default function SquareGoodsViewAndOrder(){
    const currentDate = new Date();
    const [date, setDate] = useState({day: currentDate.getDate(), month: currentDate.getMonth()+1, year: currentDate.getFullYear()})
    const [squareGoods, setSquareGoods] = useState([])
    const [activeGood, setActiveGood] = useState({})
    const [newPrice, setNewPrice] = useState("0")
    const [open, setOpen] = useState(false)
    const [shop, setShop] = useState({})
    const [shopList, setShopList] = useState([])
    useEffect(() => {
        getSquareGoods()
    }, [date])

    useEffect(() => {
        getShopsForDropDownWithOutAll(setShopList)
    }, [])



    const getSquareGoods = () => {
        Axios.post("https://"+ipaddress+"/getAllSquaregoods", {
                date: date
            },
            {
                headers: {
                    Authorization: Authorization.authdetails.authkey
                }
            }).then(res => {
                if(Array.isArray(res.data)){
                    setSquareGoods(res.data)
                } else {
                    setSquareGoods([])
                }
            }).catch(err => console.log(err))
    }
    const deleteSquaregood = (item) => {
        Axios.post("https://"+ipaddress+"/deleteSquareGoodOrder", {
                date: item.date,
                shop: item.shop
            },
            {
                headers: {
                    Authorization: Authorization.authdetails.authkey
                }
            }).then(res => {
            if(Array.isArray(res.data)){
                setSquareGoods(res.data)
            } else {
                getSquareGoods()
            }
        }).catch(err => console.log(err))
    }

    const renderItem = (item) => {
        return(
            <TouchableOpacity style={[globalStyles.listEntry, {justifyContent: "space-between"}]} onPress={() => setActiveGood(item)}>
                <Text>{item.shop}</Text>
                <View style={{flexDirection:"row"}}>
                    <Text>{item.price} kr.</Text>
                    <TouchableOpacity onPress={() => deleteSquaregood(item)}>
                        <MaterialIcons name = {"delete"} size={18}/>
                    </TouchableOpacity>
                </View>
            </TouchableOpacity>
        )
    }

    const FlatListHeader = () => {
        return (
            <View style={{alignItems:"center"}}>
                <Text style={{ fontSize: 18}}> Dagens torvevarer </Text>
            </View>
        );
    }
    const orderGoods = () => {
        Axios.post("https://"+ipaddress+"/addSquaregoodOrderWithPrice", {
                shop: shop,
                date: date,
                price: newPrice
            },
            {
                headers: {
                    Authorization: Authorization.authdetails.authkey
                }
            }).then(() => {
            getSquareGoods()
        }).catch(err => console.log(err))
    }
    const updatePrice = () => {
        Axios.post("https://"+ipaddress+"/updateSquaregoodPrice", {
                ...activeGood,
                date: date
            },
            {
                headers: {
                    Authorization: Authorization.authdetails.authkey
                }
            }).then(() => {
            getSquareGoods()
        }).catch(err => console.log(err))
    }
    return(
        <View style={[globalStyles.container]}>
            <Text style={globalStyles.title}>Torvevarer</Text>
            <View style={{flexDirection: "row"}}>
                <Text>Dato: </Text>
                <DatePicker source={date} setSource={setDate}/>
            </View>
            <FlatList
                ListHeaderComponent={FlatListHeader}
                style={{width: "70%", maxHeight: "30%", paddingTop: 5}} data={squareGoods} renderItem={({item}) => renderItem(item)}/>
            <View contentContainerStyle={{height: "40%", alignItems: "center", padding: 3, width: "100%"}}>
            {
                activeGood.price !== undefined ?
                <View style={[globalStyles.editBox, globalStyles.shadow, {flexDirection: "row", alignItems: "center", justifyContent: "flex-start"}]}>
                <Text>Ret pris </Text>
                    <TextInput style={[globalStyles.textEntryField,{width: 50}]}
                               value={activeGood.price.toString()}
                               onChangeText={text => setActiveGood({...activeGood, price: text})}/>
                    <Button title={"Ret"} onPress={updatePrice}/>

                </View>
                    :
                    null
            }
                <View style={[globalStyles.editBox, globalStyles.shadow, {alignItems: "center", width: "90%", marginTop: 4}]}>
                    <Text>Bestil torvevarer</Text>
                    <Text>Butik: </Text>
                    <DropDownPicker
                        open={open}
                        value={shop}
                        items={shopList}
                        setOpen={setOpen}
                        setValue={setShop}
                        setItems={setShopList}
                    />
                    <View style={{flexDirection: "row"}}>
                        <Text>Pris: </Text>
                        <TextInput style={[globalStyles.textEntryField,{width: 50}]}
                                   value={newPrice}
                                   onChangeText={text => setNewPrice(text)}/>
                        <Text> kr.</Text>
                    </View>
                    <Button title={"Bestil"} onPress={orderGoods}/>
                </View>
            </View>
        </View>
    )
}
