import { StyleSheet } from "react-native";

export default StyleSheet.create({
    shadow: {
        shadowColor: 'rgba(23,23,23,0.61)',
        shadowOffset: {width: -2, height: 4},
        shadowOpacity: 0.2,
        shadowRadius: 3,
    },
    listEntry: {
        borderRadius: 4,
        borderWidth: 1,
        padding: 3,
        marginVertical: 3,
        flexDirection: "row"
    },
    editBox: {
      borderWidth: 1,
      borderColor: "lightGray",
      borderRadius: 3,
      justifyContent: "space-between",
      alignItems: "center",
      padding: 3,
      backgroundColor: "white",
      width: "100%"
    },
    container: {
        width: "100%",
        alignItems: "center",
        backgroundColor: "white",
        height: "100%"
    },
    title: {
        fontSize: 25,
        textDecorationLine: "underline"
    },
    textEntryField: {
        borderWidth: 1,
        borderRadius: 3,

    }

})
