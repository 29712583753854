import React, {useEffect, useState} from 'react';
import { StyleSheet, View, Text, Button, TextInput, SafeAreaView, SectionList,FlatList } from 'react-native';
import {MaterialIcons} from '@expo/vector-icons';
import { TouchableOpacity } from 'react-native';
import {AntDesign} from '@expo/vector-icons';
import Axios from "axios";
import {ipaddress} from "../backend/backendConnection";
import Authorization from "../backend/authorization";
import DeleteButton from "./deleteButton";
import {countFlowers} from "../backend/datafunctions";


export default function FlowerView({navigation, data, reloadFunction}){
    const [sum, setSum] = useState(0)
    useEffect(() => {
        console.log("CalcPrice")
        console.log(data)
        let total = 0;
        for(const e of data) {
            for (const ee of e.data) {
                console.log(ee.price)
                total += ee.price
            }
        }
        setSum(total)
    }, [data])
    const deleteFlower = (item) => {
        Axios.post("https://"+ipaddress+"/deleteFlower", {
                ...item
            },
            {
                headers: {
                    Authorization: Authorization.authdetails.authkey
                }
            }).then(r=> reloadFunction())
    }
    const Item = ({item}) => {
        let delButton = null;
        if(item.collected === 1) {
            switch (item.delivered) {
                case 'H':
                    delButton = 'delivery-dining'
                    break
                case 'R':
                    delButton = 'arrow-back'
                    break
                case 'P':
                    delButton = 'person'
                    break
                case 'D':
                    delButton = 'sensor-door';
                    break
            }
        }
        return(
            <View style={styles.item}>
                <Text style={styles.dataText}>{item.address} </Text>
                <Text style={styles.dataText}>{item.shop} </Text>
                <Text style={styles.dataText}>{item.date} </Text>
                <View style={styles.dataText}>
                    <Text style={{fontSize: 11}}>{item.count > 1 ? "*" : ""}{item.price} kr. {item.description !== null && item.description !== undefined ? "(Spc)" : null} {item.churchDelivery === 1 ? "K": null}</Text>
                    {
                        delButton !== null ?
                            <View>
                            <MaterialIcons name = {delButton} size={18}/>
                                {   item.deliveredAtCostumer !== null ?
                                    <Text style={{fontSize: 11}}>{item.deliveredAtCostumer.substr(0,5)}</Text>
                                    : null
                                }
                            </View>
                            : null
                    }
                </View>

                <TouchableOpacity>
                    <AntDesign name='edit' size={30} onPress={() => {console.log(item); navigation.navigate('FlowerEditor', {item, reloadFunction})}}/>
                </TouchableOpacity>
                <DeleteButton deleteFunction={deleteFlower} item={item}/>
            </View>
        )}

    const Section = ({section}) => {
        let count = 0;
        try{

            count = countFlowers(section.data)

            if (! count){
                count = 0;
            }
        } catch (e) {
            count = 0;
        }
        return(
            <View style={styles.section}>
                <Text>{section.title},     {count}</Text>
                <View style={styles.columns}>
                    <Text style={styles.dataText}>Addresse:</Text>
                    <Text style={styles.dataText}>Butik:</Text>
                    <Text style={styles.dataText}>Dato:</Text>
                    <Text style={styles.dataText}>Pris:</Text>
                </View>
            </View>
        )}
    return(

        <SafeAreaView style={styles.container}>
            <View style={styles.timeSelection}>
                <SectionList
                    sections={data}
                    renderItem={({ item }) => <Item item={item} />}
                    renderSectionHeader= {({section}) => ( <Section section={section} />)}
                />
            </View>
            { sum > 0 ?
                <Text>Pris: {sum} kr.</Text>
                :
                null
            }
        </SafeAreaView>

    )
}


const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        maxHeight:"77%",
        maxWidth: "90%"
    },
    item:{
        flexDirection: 'row'
    },
    section: {
        backgroundColor: 'coral',
        fontWeight: 'bold'
    },
    dataText: {
        width: "21%",
        fontSize: 11
    },
    columns: {
        flexDirection:'row'
    },
    timeSelection: {

    }
})
