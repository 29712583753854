import React, {useEffect, useState} from 'react';
import {
    StyleSheet,
    View,
    Text,
    Button,
    TextInput,
    SafeAreaView,
    SectionList,
    FlatList,
    TouchableOpacity
} from 'react-native';
import Axios from "axios";
import Authorization from "../backend/authorization";
import {ipaddress} from "../backend/backendConnection";

export default function RouteDriverList({date, time}){
    const [freeDrivers, setFreeDrivers] = useState([])
    const [drConfig, setDrConfig] = useState([])
    const [activeDriver, setActiveDriver] = useState({})

    const updateFreeDrivers = () => {
        Axios.post("https://"+ipaddress+"/freeDrivers", {
                shop: Authorization.authdetails.referencinguser,
                date: date,
                section: time
            },
            {
                headers: {
                    Authorization: Authorization.authdetails.authkey
                }
            }).then(res => {
            if(Array.isArray(res.data))
                setFreeDrivers(res.data)
        }).catch(err => console.log(err))
    }
    const updateDrConfig = () => {
        Axios.post("https://"+ipaddress+"/driversOnRoute", {
                shop: Authorization.authdetails.referencinguser,
                date: date,
                section: time
            },
            {
                headers: {
                    Authorization: Authorization.authdetails.authkey
                }
            }).then(res => {
            if(Array.isArray(res.data))
                setDrConfig(res.data)
        }).catch(err => console.log(err))
    };

    const addDriverToRoute = (route) => {
        Axios.post("https://"+ipaddress+"/addDriverToRoute", {
                shop: Authorization.authdetails.referencinguser,
                date: date,
                section: time,
                route: route,
                driver: activeDriver.driverID
            },
            {
                headers: {
                    Authorization: Authorization.authdetails.authkey
                }
            }).then(res => {
                if(res.data === true){
                    updateDrConfig()
                    updateFreeDrivers()
                }
        }).catch(err => console.log(err,))

    }

    const removeDriverFromRoute = (driver) => {
        Axios.post("https://"+ipaddress+"/removeDriverFromRoute", {
                shop: Authorization.authdetails.referencinguser,
                date: date,
                section: time,
                driver: driver
            },
            {
                headers: {
                    Authorization: Authorization.authdetails.authkey
                }
            }).then(res => {
            if(res.data === true){
                updateDrConfig()
                updateFreeDrivers()
            }
        }).catch(err => console.log(err))
    }
    useEffect(() => {
        updateDrConfig()
        updateFreeDrivers()
    }, [date])

    return(
        <View style={{flexDirection:"row", padding: 2, borderWidth: 2}}>
            <SectionList sections={drConfig}
                         renderSectionHeader={({section}) =>
                             <TouchableOpacity style={localStyle.sectionHeader} onPress={() => {addDriverToRoute(section.title)}}>
                                 <Text>{section.title}</Text>
                             </TouchableOpacity>
                         }
                         renderItem={({item}) => {
                    return(
                        <TouchableOpacity style={localStyle.unassignedEntry} onPress={() => removeDriverFromRoute(item.id)}>
                            <Text>{item.name}</Text>
                        </TouchableOpacity>)
                }
            }/>
            <FlatList style={localStyle.borderedList} data={freeDrivers} keyExtractor={(item) => {return(item.driverID)}}
                      renderItem={({item}) => {
                return(
                    <TouchableOpacity style={[localStyle.unassignedEntry,{backgroundColor: activeDriver === item ? "lime" : null }]}
                                      onPress={()=> activeDriver === item ? setActiveDriver({}) :setActiveDriver(item)}>
                        <Text>{item.driverName}</Text>
                    </TouchableOpacity>)
            }}/>
        </View>
    )
}

const localStyle = StyleSheet.create({
    unassignedEntry: {
        borderWidth: 1,
        borderStyle: "dashed",
        marginVertical: 2,
    },
    sectionHeader: {
        padding: 2,
        margin: 2,
        borderWidth: 1,
        backgroundColor: "white"
    },
    borderedList: {
        padding:2,
        borderWidth: 1
    }
})
