import React, {useEffect, useState} from 'react';
import {
    StyleSheet,
    View,
    Text,
    Button,
    TextInput,
    SafeAreaView,
    SectionList,
    FlatList,
    TouchableOpacity, Platform, Switch
} from 'react-native';
import FlowerView from "../../components/flowerView";
import Axios from "axios";
import Authorization from "../../backend/authorization";
import DatePicker from "../../components/datePicker";
import styles from '../../globalStyles/userTypeFrontPageStyle';
import RouteDriverList from "../../components/RouteDriverList";
import RoutesWithStops from "../../components/routesWithStops";
import {ipaddress} from "../../backend/backendConnection";

export default function RouteDriverConfiguration(){
    const d = new Date();
    const [date, setDate] = useState({day: d.getDate(), month: d.getMonth() + 1, year: d.getFullYear()})
    const [morningRoute, setMorningRoute] = useState([])
    const [afternoonRoute, setAfternoonRoute] = useState([])
    const [nonWebRouteTime, setNonWebRouteTime] = useState(false)
    const updateRoutes = () => {
        Axios.post("https://"+ipaddress+"/getStopsOnMorningRoutes", {
                shop: Authorization.authdetails.referencinguser,
                date: date
            },
            {
                headers: {
                    Authorization: Authorization.authdetails.authkey
                }
            }).then(rr => {console.log("Morning",rr.data);setMorningRoute(rr.data)})
        Axios.post("https://"+ipaddress+"/getStopsOnAfternoonRoutes", {
                shop: Authorization.authdetails.referencinguser,
                date: date
            },
            {
                headers: {
                    Authorization: Authorization.authdetails.authkey
                }
            }).then(rr => {console.log(rr.data);setAfternoonRoute(rr.data)})
    }

    const generateRoutes = () => {
        Axios.post("https://"+ipaddress+"/generateRoutes", {
                shop: Authorization.authdetails.referencinguser,
                date: date
            },
            {
                headers: {
                    Authorization: Authorization.authdetails.authkey
                }
            }).then(r => {
                if(r.data===true) {
                    updateRoutes()
                }
        })
    }
    const deleteRoutes = (time) => {
        Axios.post("https://"+ipaddress+"/deleteRoutes", {
                shop: Authorization.authdetails.referencinguser,
                date: date,
                section: time
            },
            {
                headers: {
                    Authorization: Authorization.authdetails.authkey
                }
            }).then(r => {if(r.data === true){setMorningRoute([]); setAfternoonRoute([])}; updateRoutes();})
    }

    const renderRoutes = () => {
        if(Platform.OS === "web"){
            return(
            <View style={{alignItems: "center"}}>
                {morningRoute.length !== 0 ? <Text style={{fontSize: 15}}>Morgen</Text> : null}
                <RoutesWithStops sections={morningRoute} style={{marginTop: 5, width: "150%"}} reloadFunction={updateRoutes} time={0}/>
                {afternoonRoute.length !== 0 ? <Text style={{fontSize: 15}}>Eftermiddag</Text> : null}
                <RoutesWithStops sections={afternoonRoute} style={{marginTop: 5, width: "150%"}} reloadFunction={updateRoutes} time={1}/>
            </View>)
        } else {

            return(
                <View style={{flex: 1, alignItems: "center", justifyContent: "center", width:"100%"}}>
                    <View style={{flexDirection: "row"}}>
                        <Text>Morgen</Text>
                    <Switch value={nonWebRouteTime}
                            onValueChange={()=> setNonWebRouteTime(!nonWebRouteTime)}/>
                        <Text>Eftermiddag</Text>
                    </View>
                    {
                        nonWebRouteTime ? <RoutesWithStops sections={afternoonRoute} time={1} style={{flex: 1}} reloadFunction={updateRoutes}/> :
                        <RoutesWithStops sections={morningRoute} time={0} style={{flex: 1, width: "115%"}} reloadFunction={updateRoutes}/>
                    }
                </View>)
        }
    }

    return(
        <View style={localStyle.container}>
            <View style={localStyle.nonRouteArea}>
                <Text style={styles.title}>Administrer dagens rute</Text>
                <View style={{flexDirection: "row", alignItems: "center"}}>
                <DatePicker source={date} setSource={setDate}/>
                    <Button title={"Søg"} onPress={() => updateRoutes()}/>
                </View>
                    <View style={localStyle.routeToDriver}>
                        <View style={localStyle.subTitle}>
                            <Text style={{fontSize: 18}}>Morgen</Text>
                            <RouteDriverList date={date} time={0}/>
                        </View>

                        <View style={localStyle.subTitle}>
                            <Text style={{fontSize: 18}}>Eftermiddag</Text>
                            <RouteDriverList date={date} time={1}/>
                        </View>
                    </View>
                    <View style={{flexDirection: "row", marginTop: 30, justifyContent: "space-between"}}>
                        <View style={localStyle.generateButton}>
                            <TouchableOpacity onPress={() => generateRoutes()} style={{backgroundColor: "#2196f3", borderRadius: 5, borderWidth: 1}}>
                                <Text >Generer ruter</Text>
                            </TouchableOpacity>
                        </View>
                        <View style={localStyle.generateButton}>
                            <TouchableOpacity onPress={() => deleteRoutes("Morning")} style={{backgroundColor: "#2196f3", borderRadius: 5, borderWidth: 1}}>
                                <Text >Slet morgen</Text>
                            </TouchableOpacity>
                        </View>
                        <View style={localStyle.generateButton}>
                            <TouchableOpacity onPress={() => deleteRoutes("Afternoon")} style={{backgroundColor: "#2196f3", borderRadius: 5, borderWidth: 1}}>
                                <Text >Slet eftermiddag</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
            </View>
            <View style={{height:"55%", marginTop: 30}}>
                {renderRoutes()}
            </View>
        </View>
    )
}

const localStyle = StyleSheet.create(
    Platform.OS !== "web" ? {
        container: {alignItems: "center",height:"90%"},
        nonRouteArea: {flex: 1, alignItems: "center", height:"45%"},
        routeToDriver: {flexDirection:"row", flex: 7 },
        subTitle: {alignItems: "center",flex:1},
        generateButton: {alignItems: "center" }
    } : {
        container: {alignItems: "center"},
        nonRouteArea: {alignItems: "center"},
        routeToDriver: {flexDirection:"row"},
        subTitle: {alignItems: "center"},
        generateButton: {marginTop: 25, minHeight: 30, alignItems: "center" }
    }


)
