import { StyleSheet } from "react-native";

export default StyleSheet.create({
    shadow: {
        shadowColor: 'rgba(23,23,23,0.61)',
        shadowOffset: {width: -2, height: 4},
        shadowOpacity: 0.2,
        shadowRadius: 3,
    },

    stopEntry: {
        borderWidth: 1,
        borderRadius: 10,
        paddingHorizontal: 4,
        paddingVertical: 2,
        marginVertical: 2,
        width:"100%",
        borderColor: "lightgray",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    stopListStyle: {
        width: "90%"
    },
    container: {
        width: "100%",
        alignItems: "center",
        backgroundColor: "white",
        height: "100%"
    },
    title: {
        fontSize: 25,
        textDecorationLine: "underline"
    },
    routePositionButton: {width: "50%", borderWidth: 1, borderColor: "lightgray", alignItems: "center"}
})
