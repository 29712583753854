import React, {useEffect, useState} from 'react';
import {
    StyleSheet,
    View,
    Text,
    Button,
    TextInput,
    SafeAreaView,
    SectionList,
    FlatList,
    TouchableOpacity, Platform, Switch
} from 'react-native';
import Axios from "axios";
import {ipaddress} from "../backend/backendConnection";
import CryptoJS from "crypto-js";
import Authorization from "../backend/authorization";


export default function SignUp({navigation}){
    const [shopInformation, setShopInformation] = useState({})
    const [successState, setSuccessState] = useState("")
    const [approveState, setApproveState] = useState(false)
    const SignUp= () => {
        if(shopInformation.passwordOne !== shopInformation.passwordTwo){
            setSuccessState("Dine to passwords passer ikke med hinanden")
            return;
        } else {
            setSuccessState("")
        }
        Axios.post("https://"+ipaddress+"/addShop", {
            ...shopInformation,
            passwordOne: CryptoJS.SHA1(shopInformation.passwordOne).toString(),
            passwordTwo: CryptoJS.SHA1(shopInformation.passwordTwo).toString()
        }).then((response) => {
            if(response.data=== true){
                setSuccessState("Din butik er tilføjet og afventer godkendelse")
            }else {
                setSuccessState("Butikken kunne ikke oprettes")
            }
        }).catch(err => console.log(err));
    }
    return(
        <View style={styles.container}>
            <View style={styles.inputArea}>
                <View style={{alignItems: "center", width: "100%", borderBottomWidth: 2, borderColor: "black"}}>
                    <Text style={{fontSize: 27}}>Tilmeld din blomsterbutik:</Text>
                </View>
                <View style={styles.inputRow}>
                    <Text>Butiks navn: </Text>
                    <TextInput style={styles.inputField} value={shopInformation.name} maxLength={30} onChangeText={text => setShopInformation({...shopInformation, name: text})}/>
                </View>
                <View style={styles.inputRow}>
                    <Text>Vej: </Text>
                    <TextInput style={styles.inputField} value={shopInformation.street} maxLength={25} onChangeText={text => setShopInformation({...shopInformation, street: text})}/>
                </View>
                <View style={styles.inputRow}>
                    <Text>Nummer og lejlighed: </Text>
                    <TextInput style={styles.inputField} value={shopInformation.number} maxLength={15} onChangeText={text => setShopInformation({...shopInformation, number: text})}/>
                </View>
                <View style={styles.inputRow}>
                    <Text>Post nr.: </Text>
                    <TextInput style={styles.inputField} value={shopInformation.zip} maxLength={4} onChangeText={text => setShopInformation({...shopInformation, zip: text})}/>
                </View>
                <View style={styles.inputRow}>
                    <Text>By: </Text>
                    <TextInput style={styles.inputField} value={shopInformation.city} maxLength={20} onChangeText={text => setShopInformation({...shopInformation, city: text})}/>
                </View>
                <View style={styles.inputRow}>
                    <Text>Tlf: </Text>
                    <TextInput style={styles.inputField} value={shopInformation.phone} maxLength={8} onChangeText={text => setShopInformation({...shopInformation, phone: text})}/>
                </View>
                <View style={styles.inputRow}>
                    <Text>E-mail: </Text>
                    <TextInput style={styles.inputField} value={shopInformation.email} maxLength={50} onChangeText={text => setShopInformation({...shopInformation, email: text})}/>
                </View>
                <View style={styles.inputRow}>
                    <Text>Brugernavn: </Text>
                    <TextInput style={styles.inputField} value={shopInformation.username} maxLength={50}
                               onChangeText={text => setShopInformation({...shopInformation, username: text})}/>
                </View>
                <View style={styles.inputRow}>
                    <Text>Password: </Text>
                    <TextInput style={styles.inputField} value={shopInformation.passwordOne} maxLength={50} secureTextEntry={true} onChangeText={text => setShopInformation({...shopInformation, passwordOne: text})}/>
                </View>
                <View style={styles.inputRow}>
                    <Text>Gentag password: </Text>
                    <TextInput style={styles.inputField} value={shopInformation.passwordTwo} maxLength={50} secureTextEntry={true}
                               onChangeText={text => {setShopInformation({...shopInformation, passwordTwo: text})}}/>
                </View>


                <Text>{successState}</Text>
                {
                    approveState ?
                        <View>
                            <Button title={"Ja"} onPress={() => {setApproveState(false); SignUp()}}/>
                            <View style={{marginTop: 3}}/>
                            <Button title={"Nej"} onPress={() => setApproveState(false)}/>
                        </View>
                        :
                        <Button title={"Tilmeld"} onPress={() => {setApproveState(true); setSuccessState("Vil du oprette en butik?")}}/>
                }
            </View>
        </View>
    )
}


const styles = StyleSheet.create({
    container: {
        width: "100%",
        alignItems: "center",
        backgroundColor: "white",
        height: "100%"
    },
    choiceArea: {
        flexDirection:"row",
        padding : 10,
        borderBottomWidth: 1
    },
    inputArea: {
        width: Platform.OS !== "web" ? "100%" : "30%"
    },
    choiceButton: {
        aspectRatio: 3,
        width: "45%",
        borderRadius: 3,
        borderWidth: 1,
        alignItems: "center",
        justifyContent: "center",
        marginHorizontal: 10,
        shadowColor: '#171717',
        shadowOffset: {width: -2, height: 4},
        shadowOpacity: 0.3,
        shadowRadius: 3,
    },
    buttonText: {
        fontSize: 14
    },
    inputRow: {
        flexDirection: "row",
        width:"100%",
        justifyContent: "space-between",
        paddingHorizontal: 10,
        alignContent: "center",
        marginVertical: 3
    },
    inputField: {
        width:"40%",
        borderWidth: 1,
        borderRadius: 5,
        minWidth: Platform.OS === "web" ? 100 : null
    },
})
/*<View style={styles.choiceArea}>
    <TouchableOpacity onPress={() => setDriverSignUp(!driverSignUp)} style={[styles.choiceButton, {backgroundColor: driverSignUp ? 'rgba(0,0,255, 0.7)' : 'rgba(0,0,255, 0.2)'}]}>
        <Text>Chauffør</Text>
    </TouchableOpacity>
    <TouchableOpacity onPress={() => setDriverSignUp(!driverSignUp)} style={[styles.choiceButton, {backgroundColor: !driverSignUp ? 'rgba(0,0,255, 0.7)' : 'rgba(0,0,255, 0.2)'}]}>
        <Text>Butik</Text>
    </TouchableOpacity>
</View>*/
/*
*
            {
                driverSignUp ?
                    <View style={styles.inputArea}>
                        <View style={styles.inputRow}>
                            <Text>Navn: </Text>
                            <TextInput style={styles.inputField} value={driverInformation.name} onChangeText={text => setDriverInformation({...driverInformation, name: text})}/>
                        </View>
                        <Text>{succesState}</Text>
                        <Button title={"Tilmeld"} onPress={() => console.log(driverInformation)}/>
                    </View>
                    :


            }*/
