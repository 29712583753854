import {FlatList, Text, TextInput, TouchableOpacity, View} from "react-native";
import {useState} from "react";
import newGlobalStyles from "../globalStyles/newGlobalStyles";
import Axios from "axios";
import {ipaddress} from "../backend/backendConnection";
function VariantsBox(props) {
    return null;
}

export default function DropdownTextInput({callback}){
    const [suggestions, setSuggestions] = useState([]);
    const [searchAddress, setSearchAddress] = useState('');
    const [listOffset, setListOffset] = useState(0);
    const updateSearch = value => {
        setSearchAddress(value)
        getAddressSuggestions(value)
    };
    const getAddressSuggestions = (val) => {
        Axios.post("https://"+ipaddress+"/getAddressSuggestion", {
            input: val
        }).then(r => {
            setSuggestions(r.data)
        }).catch(err => console.log(err))
    }
    const onAddressSelected = value => {
        callback(value)
        setSearchAddress("");
        setSuggestions([]);
    };
    return(
        <View style={{width: "100%", zIndex: 1}}>
            <TextInput
                value={searchAddress}
                placeholder={"Søg"}
                onChangeText={updateSearch}
                style={newGlobalStyles.editBox}
                onLayout={(event) => {
                    const {x,y,width,height} = event.nativeEvent.layout;
                    console.log(event.nativeEvent.layout)
                    setListOffset(height)
                }}
            />
            <FlatList
                nestedScrollEnabled={true}
                style={{position: "absolute", zIndex: 100, backgroundColor: "white", width:"100%", marginTop: listOffset}}
                data={suggestions}
                renderItem={({item, index}) => (
                    <TouchableOpacity
                        style={[newGlobalStyles.listEntry, {backgroundColor: "white", zIndex: 101}]}
                        onPress={() => onAddressSelected(item)}>
                            <Text>
                                {item?.description || ''}
                            </Text>
                    </TouchableOpacity>
                )}
                keyExtractor={item => item.description}
            />
        </View>
    )

}
