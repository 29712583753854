import React, {useEffect, useState} from 'react';
import {
    StyleSheet,
    View,
    Text,
    Button,
    TextInput,
    SafeAreaView,
    SectionList,
    FlatList,
    Platform,
    TouchableOpacity
} from 'react-native';
import {AntDesign} from "@expo/vector-icons";
import Axios from "axios";
import {ipaddress} from "../backend/backendConnection";
import Authorization from "../backend/authorization";
import {countFlowers} from "../backend/datafunctions";

export default function RoutesWithStops({sections, style, reloadFunction,time}){
    const [pressedItems, setPressedItems] = useState([])
    const [lastPress, setLastPress] = useState(0)
    const changeDriver = (newDriver) => {
        for(const pressedItem of pressedItems) {
            if (pressedItem.street === undefined) {
                Axios.post("https://" + ipaddress + "/changeSquaregoodDriver", {
                        shop: pressedItem.shop,
                        newDriver: newDriver,
                        date: pressedItem.date
                    },
                    {
                        headers: {
                            Authorization: Authorization.authdetails.authkey
                        }
                    }).then(r => {
                    if (r.data === true) {
                        reloadFunction()
                    }
                })
            }
            console.log(pressedItem)
            Axios.post("https://"+ipaddress+"/changeDriver", {
                    shop: pressedItem.shop,
                    street: pressedItem.street,
                    number: pressedItem.number,
                    zip: pressedItem.zip,
                    city: pressedItem.city,
                    deliveryDate: pressedItem.deliveryDate,
                    timeSlot: time,
                    newDriver: newDriver,
                    churchChange: pressedItem.cd === 1,
                    afternoon: pressedItem.afternoon === 1,
                    collectionDate: pressedItem.collectionDate
                },
                {
                    headers: {
                        Authorization: Authorization.authdetails.authkey
                    }
                }).then(r => {
                if (r.data === true) {
                    reloadFunction()
                }
            })
        }
        setPressedItems([])
    }
    const Header = ({section}) => {
        let driver = "";
        let from = 0;
        let count = 0;
        try{

            if(time === 0){
                count = countFlowers(sections.filter(e => e.title === section.title)[0].data)
            } else {
                count = countFlowers(sections.filter(e => e.title === section.title)[0].data)
            }
            if (! count){
                count = 0;
            }
        } catch (e) {
            count = 0;
        }
        while(section.title[from] !== ","){
            from++;
        }
        from+=2;
        driver = section.title.substr(from);
        return(
            <TouchableOpacity style={localStyle.header} onPress={() => changeDriver(driver)}>
                <Text>{section.title}, {count}</Text>
                <View style={localStyle.columns}>
                    <Text style={localStyle.columnTag}>Butik:</Text>
                    <Text style={localStyle.columnTag}>Levering:</Text>
                    <Text style={localStyle.columnTag}>Kirke:</Text>
                    <Text style={localStyle.columnTag}>Status:</Text>
                </View>
            </TouchableOpacity>
        )
    }

    const handlePress = (item) => {
        if(pressedItems.includes(item))
            setPressedItems(pressedItems.filter(e => e !== item))
        else
            setPressedItems([...pressedItems, item])

    }

    const setChurchToAfternoon = (item) => {
        Axios.post("https://"+ipaddress+"/setAfternoonChurch", {
                shop: item.shop,
                street: item.street,
                number: item.number,
                zip: item.zip,
                city:item.city,
                deliveryDate: item.deliveryDate,
                value: item.afternoon === 0,
                collectionDate: item.collectionDate
            },
            {
                headers: {
                    Authorization: Authorization.authdetails.authkey
                }
            }).then(r => {
            if(r.data === true){
                reloadFunction()
            }
        })
    }

    const Entry = ({item}) => {
        if(item.street === undefined){
            return (
                <TouchableOpacity onPress={() =>handlePress(item)} style={[localStyle.columns, localStyle.entry, {backgroundColor: pressedItems.includes(item) ? "lime" : null}]}>
                    <Text style={{width:"100%"}}>Torvevarer {item.shop}</Text>

                </TouchableOpacity>
            )
        }
        const address = item.street+", "+item.number+", "+item.city
        let church = "Nej"
        if(item.cd === 1) {
            church = item.church + ", " + item.dt.substr(3)
            if (item.ps === 1)
                church += ", B"
            else
                church += ", T"
            if(item.afternoon === 1){
                church+= ", Eftermiddag"
            }
        }

        let status = item.count > 1 ? "*" : "";
        if(item.collected === 1)
            status += "Afhentet, "
        switch (item.status){
            case 'P': {
                status += "Pers. Lev";
                break
            }
            case 'D':{
                status += "Dør";
                break
            }
            case 'R': {
                status += "Retur";
                break
            }
        }
        return(
            <TouchableOpacity onPress={() => handlePress(item)} style={[localStyle.columns, localStyle.entry, {backgroundColor: pressedItems.includes(item) ? "lime" : null}]}>
                <Text style={localStyle.rowTag}>{item.shop}</Text>
                <Text style={localStyle.rowTag}>{address}</Text>
                <Text style={localStyle.rowTag}>{church}</Text>
                <Text style={localStyle.rowTag}>{status}</Text>
                {   item.cd === 1?
                    <TouchableOpacity style={localStyle.moveChurchFlowerButton} onPress={() => setChurchToAfternoon(item)}>
                        <AntDesign name={"arrowup"} size={10}/>
                        <AntDesign name={"arrowdown"} size={10}/>
                    </TouchableOpacity>
                    : null
                }
            </TouchableOpacity>
        )
    }

    return(
        <View style={localStyle.listStyle}>
            <SectionList sections={sections}
                         keyExtractor={(item) => item.shop+item.street+item.number+item.zip+item.city}
                         renderSectionHeader={({section}) => <Header section={section}/> }
                         renderItem={({item}) => {return(<Entry item={item}/>)}}
            />
        </View>
    )
}


const localStyle = StyleSheet.create({
    listStyle: {
        maxWidth: "95%"
    },
    header: {
        backgroundColor: "coral",
        marginBottom: 1
    },
    columns: {
        flexDirection: "row"
    },
    columnTag:{
        width: "20%"
    },
    rowTag: {
        width: "20%",
        fontSize: Platform.OS === "web" ? 12 : 11
    },
    moveChurchFlowerButton: {
        flexDirection:"row"
    },
    entry: {
        borderWidth: 1,
        borderStyle: "dashed",
        marginVertical: 0.5
    }
})
