import React, {useState} from 'react';
import {
    StyleSheet,
    View,
    Text,
    Button,
    TextInput,
    SafeAreaView,
    SectionList,
    FlatList,
    TouchableOpacity
} from 'react-native';
import {MaterialIcons} from '@expo/vector-icons';
import styles from "../globalStyles/routePlanningFonts"
export default function StandardButton({title, onPress, style}){
    return(
        <TouchableOpacity style={[localStyles.buttonStyle, styles.shadow, style]} onPress={() => onPress()}>
            <Text>{title}</Text>
        </TouchableOpacity>
    )
}

const localStyles = StyleSheet.create({
    buttonStyle: {
        borderWidth: 1,
        borderRadius: 20,
        paddingVertical: 5,
        paddingHorizontal: 7,
        width: "100%",
        alignItems: "center"
    }
})
