import React, {useEffect, useRef, useState} from 'react';
import {
    StyleSheet,
    View,
    Text,
    Button,
    TextInput,
    Platform,
    TouchableOpacity,
    FlatList,
    Alert,
    Linking
} from 'react-native';
import Axios from 'axios';
import Authorization from "../../backend/authorization";
import {ipaddress} from "../../backend/backendConnection";
import styles from "../../globalStyles/routePlanningFonts";
import {MaterialIcons} from "@expo/vector-icons";
import AddressInput from "../../components/AddressInput";
import {titlePlaceholder} from "react-native/template.config";

export default function AfternoonNavigationPage({navigation, route}){
    const [stops, setStops] = useState([])
    const [activeFlag, setActiveFlag] = useState("none")
    const [passedStops, setPassedStops] = useState([])
    const [lastPress, setLastPress] = useState(new Date())
    const [totalTime, setTotalTime] = useState({hours: "0", min: "0"})
    useEffect(() => {
        let timePassed = 0
        for(const e of passedStops){
            if(e.legtime !== 0 && !e.legtime)
                return
            else timePassed += parseInt(e.legtime)
        }
        let timeTotal = 0
        for(const e of stops){
            if(e.legtime !== 0 && !e.legtime)
                return
            else timeTotal += parseInt(e.legtime)
        }
        console.log(timePassed, timeTotal)
        const calTime = timeTotal - timePassed;
        let minutes = Math.floor((calTime%3600)/60).toString();
        minutes = minutes.length === 1 ? "0"+minutes : minutes;
        setTotalTime({hours: (Math.floor(calTime/3600)).toString(), min: minutes})
    },[stops, passedStops])

    useEffect(() => {
        Axios.post("https://"+ipaddress+"/saveAfternoonRoute", {
            date: navigation.state.params.date,
            passed: passedStops,
            stops:stops,
            driver: Authorization.authdetails.referencinguser
        }, {
            headers: {
                Authorization: Authorization.authdetails.authkey
            }
        }).catch(err => console.log(err))
    }, [passedStops])

    const getStops = () => {
        console.log("Getting")
        Axios.post("https://"+ipaddress+"/getOptimizedRoute", {
            date: navigation.state.params.date,
            driver: Authorization.authdetails.referencinguser,
            route: "Afternoon"
        }, {
            headers: {
                Authorization: Authorization.authdetails.authkey
            }
        }).then(r => {
            console.log("Optimized route", r.data)
            if(r.data.length < 1) {
                Axios.post("https://"+ipaddress+"/getFlowersOnAfternoonRoute", {
                    date: navigation.state.params.date,
                }, {
                    headers: {
                        Authorization: Authorization.authdetails.authkey
                    }
                }).then(rr => {
                    let data = []
                    for(const e of rr.data){
                        data.push({...e, address: e.street+" "+e.number+", "+e.zip+" "+e.city})
                    }
                    setStops(data)
                }).catch(err => console.log("ORIGINAL ERR: ", err))
            } else {
                setStops(r.data)
                if(r.data[0] !== undefined && r.data[0].passed !== undefined) {
                    setPassedStops(r.data.filter(e => e.passed === 1))
                }
            }
        }).catch(err => console.log(err))
    }

    const optimizeRoute = () => {
        console.log("Optimizing")
        Axios.post("https://"+ipaddress+"/optimizeRoute", {
            user: Authorization.authdetails.referencinguser,
            timeslot: "Afternoon",
            date: navigation.state.params.date,
            all_addresses: [...stops],
            passed_addresses: [...passedStops]
        }, {
            headers: {
                Authorization: Authorization.authdetails.authkey
            }
        }).then(r => {
            if(r.data === true){
                getStops()
            } else if(r.data === "NONE_ROUTE_FOUND"){
                Alert.alert("Fejl", "Ruten kunne ikke optimeres")
            }
        }).catch(err => console.log(err))
    }
    const deletePlan = () => {
        Axios.post("https://"+ipaddress+"/deletePlannedRoute", {
            driver: Authorization.authdetails.referencinguser,
            timeslot: "Afternoon",
            date: navigation.state.params.date,
        }, {
            headers: {
                Authorization: Authorization.authdetails.authkey
            }
        }).then(r => {console.log(r.data); if(r.data === true){
            getStops()
            setPassedStops([])
        } }).catch(err => console.log(err))
    }

    const navigateToNextStop = () => {
        let nextStop = {}
        for(const e of stops){
            if(!passedStops.includes(e)){
                nextStop = e;
                break;
            }
        }


        const scheme = Platform.select({ ios: 'maps:0,0?q=', android: 'geo:0,0?q=' });
        const label = 'Custom Label';
        const address = nextStop.address
        const url = Platform.select({
            ios: `${scheme}${address}`,
            android: `${scheme}${address}`
        });
        Linking.openURL(url);
    }

    const removeStop = (item) => {
        Alert.alert(
            "Advarsel!",
            "Vil du gerne slette dette stop: " + item.address,
            [
                {
                    text: "Ja",
                    onPress: () => setStops(stops.filter(i => item !== i)),
                },
                {
                    text: "Nej"
                }
            ]
        )

    }
    const entryPressed = (item) => {
        activeFlag === "Green" ? switchStopPosition(item, 0) :
            activeFlag === "Red" ? switchStopPosition(item, stops.length-1) : null;
        setActiveFlag("none")
        const t = new Date();
        if(t-lastPress < 250){
            if(passedStops.indexOf(item) === -1)
                setPassedStops([...passedStops, item])
            else {
                setPassedStops(passedStops.filter(i => item !== i))
            }
        }
        setLastPress(t)

    }

    const switchStopPosition = (item, index) => {
        let arr = []
        for(const e of stops)
            arr.push(e)
        const oldItem = arr[index]
        for(let i = 0; i < arr.length; i++)
            if(arr[i] === item) {
                arr[index] = arr[i]
                arr[i] = oldItem
                break
            }
        setStops(arr)
    }
    useEffect(() => {
        getStops()
    }, [])
    return(
        <View style={styles.container}>
            <Text style={styles.title}>Planlæg ruten:</Text>
            <View style={{width: "90%", marginVertical: 10}}>
            <AddressInput callback={text => {
                setStops([...stops, {address: text}])
            }}/>
            </View>
            <View style={{flexDirection: "row", width: "100%", marginTop: 10, marginVertical: 15}}>
                <TouchableOpacity style={[styles.routePositionButton, {backgroundColor: activeFlag === "Green" ? 'rgba(0, 256, 0, 0.2)' : null}]}
                                  onPress={() => activeFlag === "Green" ? setActiveFlag("none") : setActiveFlag("Green")}>
                    <MaterialIcons name={"flag"} size={50} color={"green"}/>
                </TouchableOpacity>
                <TouchableOpacity style={[styles.routePositionButton, {borderLeftWidth: 0}, {backgroundColor: activeFlag === "Red" ? 'rgba(256, 0, 0, 0.2)' : null}]}
                                  onPress={() => activeFlag === "Red" ? setActiveFlag("none") : setActiveFlag("Red")}>
                    <MaterialIcons name={"flag"} size={50} color={"red"}/>
                </TouchableOpacity>
            </View>
            <FlatList data={stops}
                      style={styles.stopListStyle}
                      renderItem={({item}) => {
                          return(
                              <TouchableOpacity style={[styles.stopEntry, styles.shadow, {backgroundColor: passedStops.indexOf(item) === -1 ? null : "aqua"}]}
                                                onPress={() => {entryPressed(item)}}>
                                  <View style={{flexDirection: "row", alignItems:"center"}}>
                                      <TouchableOpacity onPress={() => removeStop(item)}>
                                          <MaterialIcons name={"delete"} size={25}/>
                                      </TouchableOpacity>
                                      <Text style={{fontSize: 15}}>{item.address}</Text>
                                  </View>
                                  {
                                      item === stops[0] ? <MaterialIcons name={"flag"} size={25} color={"green"}/> : null
                                  }
                                  {
                                      item === stops[stops.length-1] ? <MaterialIcons name={"flag"} size={25} color={"red"}/> : null
                                  }
                              </TouchableOpacity>
                          )
                      }}/>
            <Text>Tid tilbage: {totalTime.hours}:{totalTime.min}</Text>
            <View style={{marginBottom: 10, flexDirection: "row"}}>
                <Button title={"Optimer ruten"} onPress={() => optimizeRoute()}/>
                <Button title={"Næste stop"} onPress={() => {
                    navigateToNextStop()
                }}/>
                <Button title={"Slet plan"} onPress={() => {
                    deletePlan();
                }}/>
            </View>
        </View>
    )
}
