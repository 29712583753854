import React, {useState} from 'react';
import { StyleSheet, View, Text, Button, TextInput } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { getAllDrivers } from '../../backend/backendConnection';
import styles from '../../globalStyles/userTypeFrontPageStyle';

export default function AdminFrontPage({navigation}) {

    return(
        <View style={styles.container}>
            <View style={styles.choiseLine}>
                <TouchableOpacity style={styles.choiseButton} onPress={() => navigation.navigate('DriverWorkingDaysOverview')}>
                    <Text>Medarbejder-</Text>
                    <Text>oversigt</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.choiseButton} onPress={() => navigation.navigate('FlowerOverview')}>
                    <Text>Blomster-</Text>
                    <Text>oversigt</Text>
                </TouchableOpacity>
            </View>
            <View style={styles.choiseLine}>
                <TouchableOpacity style={styles.choiseButton} onPress={() => navigation.navigate('Routes')}>
                    <Text>Rute-</Text>
                    <Text>planlægning</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.choiseButton} onPress={() => navigation.navigate('RouteDriverConfiguration')}>
                    <Text>Dagens</Text>
                    <Text>rute</Text>
                </TouchableOpacity>
            </View>
            <View style={styles.choiseLine}>
                <TouchableOpacity style={styles.choiseButton} onPress={() => navigation.navigate('ShopOverview')}>
                    <Text>Butiks-</Text>
                    <Text>oversigt</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.choiseButton} onPress={() => navigation.navigate('DriverOverview')}>
                    <Text>Chauffører</Text>
                </TouchableOpacity>
            </View>
            <View style={styles.choiseLine}>
                <TouchableOpacity style={styles.choiseButton} onPress={() => navigation.navigate('PricingPage')}>
                    <Text>Prissætning</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.choiseButton} onPress={() => navigation.navigate('SquareGoodsViewAndOrder')}>
                    <Text>Torvevarer</Text>
                </TouchableOpacity>
            </View>

            <View style={styles.choiseLine}>
                <TouchableOpacity style={styles.choiseButton} onPress={() => navigation.navigate('OrderFlowerPage')}>
                    <Text>Tilføj-</Text>
                    <Text>blomst</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.choiseButton} onPress={() => navigation.navigate('MorningRoutePlanner')}>
                    <Text>Morgen-</Text>
                    <Text>plan</Text>
                </TouchableOpacity>
            </View>
        </View>
    )
}

