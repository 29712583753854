import { StyleSheet } from "react-native"

export default StyleSheet.create({
    container: {
        alignItems: 'center',
        flex: 1,
    },
    choiseLine: {
        width: 200,
        flexDirection: 'row'
    },
    choiseButton: {
        alignItems: 'center',
        margin: 5,
        backgroundColor: 'coral',
        width: 90,
        borderColor: 'black',
        borderRadius: 3,
        borderWidth: 2
    },
    title: {
        fontSize: 24
    }
})
