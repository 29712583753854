import React from 'react';
import {StyleSheet, View, Text, Button, TouchableOpacity} from 'react-native';
import Header from '../components/header';

export default function ChooseUserType({ navigation }){

    const pressHandler = () => {
        navigation.navigate('Login')
    }

    return(
        <View style={styles.container}>
            <TouchableOpacity onPress={pressHandler} style={[styles.loginChoise, styles.shadowProp]}>
                <Text>Login</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={()=> navigation.navigate('SignUp')} style={[styles.loginChoise, styles.shadowProp]}>
                <Text>Tilmeld</Text>
            </TouchableOpacity>
        </View>
    )
}

const styles = StyleSheet.create({
    container:{
        flex: 1,
        alignItems: 'center',
        backgroundColor: 'white'
    },
    loginChoise: {
        borderRadius: 3,
        borderWidth: 1,
        backgroundColor: 'coral',
        width: 80,
        height: 80,
        alignItems: 'center',
        justifyContent: 'center',
        marginVertical: 10
    },
    shadowProp: {
        shadowColor: '#171717',
        shadowOffset: {width: -2, height: 4},
        shadowOpacity: 0.2,
        shadowRadius: 3,
    },
})
