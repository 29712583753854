import {Alert, Button, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import React, {useEffect, useState} from "react";
import { NestableScrollContainer, NestableDraggableFlatList } from "react-native-draggable-flatlist";
import {ScaleDecorator} from "react-native-draggable-flatlist/src/components/CellDecorators";
import DraggableFlatList from "react-native-draggable-flatlist/src/components/DraggableFlatList";
import newGlobalStyles from "../../globalStyles/newGlobalStyles";
import DatePicker from "../../components/datePicker";
import Axios from "axios";
import {ipaddress} from "../../backend/backendConnection";
import Authorization from "../../backend/authorization";
import CyclicalPicker from "../../components/CyclicalPicker";
import {MaterialIcons} from "@expo/vector-icons";
import AddressInput from "../../components/AddressInput";

export default function MorningRoutePlanner(){
    const d = new Date();
    const [date, setDate] = useState({day: d.getDate(), month: d.getMonth()+1, year: d.getFullYear()%2000})
    const [drivers, setDrivers] = useState([]);
    const [driverIndex, setDriverIndex] = useState(0);
    const [stops, setStops] = useState([])
    const backgroundColor = "white";
    const NUM_ITEMS = 10;
    const [data, setData] = useState([]);

    useEffect(() => {
        getDrivers()
        console.log(2)
    }, [date])

    useEffect(() => {
        getStops()
        console.log(1)
    }, [date, drivers, driverIndex])
    const getDrivers = () => {
        Axios.post("https://"+ipaddress+"/getMorningDrivers",{
            date: date
        },{
            headers: {
                Authorization: Authorization.authdetails.authkey
            }
        }).then(r => {
            if(r.data !== false){
                setDriverIndex(0)
                setDrivers(r.data)
            }
        }).catch(err => console.log(err))
    }

    const getStops = () => {
        if(drivers[driverIndex]) {
            Axios.post("https://"+ipaddress+"/getAllDriverStopAddressesOnMorningRoute", {
                date: date,
                driverID: drivers[driverIndex].driverID
            }, {
                headers: {
                    Authorization: Authorization.authdetails.authkey
                }
            }).then(r => {
                console.log(date, r.data)
                if (r.data && r.data !== false) {
                    setStops(r.data)
                    const interData = r.data.map(e => {

                        return{
                            key: `item-${e+Math.random() * 4000}`,
                            label: e.address,
                            height: 100,
                            width: 60 + Math.random() * 40,
                            backgroundColor,
                        }
                    })
                    setData(interData)
                }
            }).catch(err => {console.log(err)})
        }
    }

    const renderItem = ({ item, drag, isActive }) => {
        return (
            <ScaleDecorator>
                <TouchableOpacity
                    onLongPress={drag}
                    disabled={isActive}
                    style={[
                        styles.rowItem,
                        { backgroundColor: isActive ? "aqua" : item.backgroundColor },
                    ]}
                >
                    <TouchableOpacity onPress={() => removeStop(item)}>
                        <MaterialIcons name={"delete"} size={25}/>
                    </TouchableOpacity>
                    <Text style={styles.text}>{item.label}</Text>
                </TouchableOpacity>
            </ScaleDecorator>
        );
    };

    const deletePlan = () => {
        Axios.post("https://"+ipaddress+"/deletePlannedRoute", {
            driver: drivers[driverIndex].driverID,
            timeslot: "Morning",
            date: date,
        }, {
            headers: {
                Authorization: Authorization.authdetails.authkey
            }
        }).then(r => {console.log(r.data); if(r.data === true){
            getStops()
        } }).catch(err => console.log(err))
    }

    const savePlan = () => {
        Axios.post("https://"+ipaddress+"/generateMorningPlan", {
            date: date,
            driverId: drivers[driverIndex].driverID,
            stops: data
        }, {
            headers: {
                Authorization: Authorization.authdetails.authkey
            }
        }).then(r => {}).catch(err => console.log(err))
    }

    const removeStop = (item) => {
        Alert.alert(
            "Advarsel!",
            "Vil du gerne slette dette stop: " + item.label,
            [
                {
                    text: "Ja",
                    onPress: () => setData(data.filter(i => {
                        return item.label !== i.label
                    })),
                },
                {
                    text: "Nej"
                }
            ]
        )

    }
    return(
        <View style={newGlobalStyles.container}>
            <DatePicker source={date} setSource={setDate}/>
            {
                drivers.length > 0 ?

                    <View style={{flexDirection: "row"}}>

                        <TouchableOpacity onPress={() => {
                            if(driverIndex === 0){
                                setDriverIndex(drivers.length-1)
                                getStops()
                            } else {
                                getStops()
                                setDriverIndex(driverIndex-1)
                            }
                        }}>
                            <MaterialIcons name={'chevron-left'} size={30}/>
                        </TouchableOpacity>
                        <Text style={{fontSize: 20}}>{drivers[driverIndex].DriverName}</Text>
                        <TouchableOpacity onPress={() => {
                            if(driverIndex === drivers.length-1){
                                setDriverIndex(0)
                            } else {
                                setDriverIndex(driverIndex+1)
                            }}}>
                            <MaterialIcons name={'chevron-right'} size={30}/>
                        </TouchableOpacity>
                    </View>
                    : null
            }
            <View style={{width:"90%"}}>
            <AddressInput callback={e => {
                setData([...data, {
                    key: `item-${e+Math.random() * 4000}`,
                    label: e.address,
                    height: 100,
                    width: 60 + Math.random() * 40,
                    backgroundColor,
                }])
            }}/>
            </View>
            <View style={{flexDirection: "row"}}>
                <Button title={"Gem"} onPress={() => {savePlan()}}/>
                <Button title={"Slet"} onPress={() => {deletePlan()}}/>
            </View>
            <View style={{width:"100%", height: "80%", borderBottomWidth: 1}}>
            <DraggableFlatList
                data={data}
                onDragEnd={({ data }) => setData(data)}
                keyExtractor={(item) => item.key}
                renderItem={renderItem}
            />
            </View>
        </View>
    )
}
const styles = StyleSheet.create({
    rowItem: {
        height: 100,
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row"
    },
    text: {
        color: "black",
        fontSize: 24,
        fontWeight: "bold",
        textAlign: "center",
    },
});
