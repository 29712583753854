import React, {Component, useEffect, useState} from "react";
import {
    View,
    FlatList,
    Text,
    TextInput,
    Button,
    StyleSheet,
    TouchableOpacity,
    BackHandler,
    SectionList
} from "react-native";
import {MaterialIcons} from "@expo/vector-icons";

export default function RoutesList({dataFunction, addFunction, deleteFunction, title, onChange}){
    const [data, setData] = useState([])
    const [newName, setNewName] = useState('')
    const [response, setResponse] = useState("")
    useEffect(() => {
        dataFunction(setData)
    }, [newName, response])
    const Entry = ({name}) => {
        return(
            <TouchableOpacity
                style={{flexDirection: "row", marginVertical: 2, borderStyle: "dashed", borderWidth: 1, justifyContent: "space-between"}}>
                <Text>{name.name}</Text>
                <TouchableOpacity onPress={() => {deleteFunction(name.name, setResponse); onChange() }}>
                    <MaterialIcons name='delete' size={20} />
                </TouchableOpacity>
            </TouchableOpacity>
        )
    }
    return(
        <View style={localStyle.main}>
            <Text style={localStyle.title}>{title}</Text>
            <FlatList style={{width: "100%"}} data={data} renderItem={({item}) => <Entry name={item}/>}/>
            <View style={{marginTop: 2}}>
                <TextInput value={newName} onChangeText={setNewName} placeholder={"Navn"} style={localStyle.inputField}/>
                <Button title={"Tilføj"} onPress={() => {addFunction(newName, setResponse); if(response === ''){ setNewName('');}onChange(); }}/>
                <Text style={{color: "red"}}>{response}</Text>
            </View>
        </View>
    )
}

const localStyle = StyleSheet.create({
    title: {
        fontSize: 18,
        textDecorationLine: "underline"
    },
    main:{
        alignItems: "center",
        borderWidth: 2,
        backgroundColor: "white",
        padding: 5
    },
    inputField:{
        borderWidth:1,
        borderRadius: 2,
    },
})
