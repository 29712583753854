import React, {Component, useEffect, useState} from "react";
import {
    View,
    FlatList,
    Text,
    TextInput,
    Button,
    StyleSheet,
    TouchableOpacity,
    BackHandler,
    SectionList
} from "react-native";
import {MaterialIcons} from "@expo/vector-icons";
import CyclicalPicker from "../../components/CyclicalPicker";
import Axios from "axios";
import {ipaddress} from "../../backend/backendConnection";
import Authorization from "../../backend/authorization";
import DropDownPicker from "react-native-dropdown-picker";

Date.prototype.getWeek = function() {
    var date = new Date(this.getTime());
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    // January 4 is always in week 1.
    var week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
        - 3 + (week1.getDay() + 6) % 7) / 7);
}

export default function WorkingDays(){
    const date = new Date()
    const days = ['Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag','Søndag'];
    const [year, setYear] = useState(date.getFullYear())
    const [week, setWeek] = useState(date.getWeek())
    const [workingDays, setWorkingDays] = useState([])

    useEffect(() => {
        Axios.post("https://"+ipaddress+"/getWorkingDays", {
            driverId: Authorization.authdetails.referencinguser,
            week: week,
            year: year%2000
        }, {
            headers: {
                Authorization: Authorization.authdetails.authkey
            }
        }).then(r => {console.log(r.data);setWorkingDays(r.data)})
    }, [year, week])


    return(
        <View style={style.container}>
            <Text style={{fontSize: 30, textDecorationLine:"underline"}}>Arbejdstider</Text>
            <CyclicalPicker value={year} setValue={setYear} title={"År"}/>
            <CyclicalPicker value={week} setValue={setWeek} title={"Uge"} maxVal={53} minVal={1}/>
            <FlatList style={style.listStyle} data={workingDays} renderItem={({item}) =>
                <Text style={style.workingTime}>{days[item.day]}, {item.section === 1 ? "Morgen" : "Eftermiddag"}</Text>}/>
        </View>
    )

}

const style = StyleSheet.create({
    container:{
        width: "100%",
        alignItems: "center",
        backgroundColor: "white",
        height: "100%"
    },
    workingTime: {
        width: "100%",
        borderWidth: 1,
        marginTop: 2,
        borderColor: "lightgray",
        paddingLeft: 5,
        borderRadius: 10,
        shadowColor: '#171717',
        shadowOffset: {width: -2, height: 4},
        shadowOpacity: 0.2,
        shadowRadius: 3,
    },
    listStyle: {
        width: "80%",

    }
})
