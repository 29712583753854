import React, {useState} from 'react';
import { StyleSheet, View, Text, Button, TextInput, SafeAreaView, SectionList,FlatList } from 'react-native';
import {MaterialIcons} from '@expo/vector-icons';
import StandardButton from "./StandardButton";

export default function AddressInput({callback}){
    const [text, setText] = useState("")
    return(
        <View style={{width: "100%", flexDirection: "row"}}>
            <TextInput style={{borderWidth: 1, borderRadius: 3, width: "80%", marginRight: 10}} value={text}
                       placeholder={"Adresse"}
                       onChangeText={setText}/>
            <StandardButton title={"Tilføj"} onPress={() => callback(text)} style={{width:"20%"}}/>
        </View>
    )
}

