import {FlatList, Platform, SectionList, StyleSheet, Text, TouchableOpacity, View} from "react-native";

import React, {useEffect, useState} from "react";

export default function RouteConfiguration({getRoutesWithStops, getNonPlannedStops, add, remove, routes, setRoutes, title, alert}){
    const [zip, setZip] = useState([])

    const [unassignedZipsMax, setUnassignedZipsMax] = useState(98);
    const [activeZip, setActiveZip] = useState([])
    const onLayout =(event) => {
        const {x, y, height, width} = event.nativeEvent.layout;
        setUnassignedZipsMax(height-5)
    }

    useEffect(()=>{
        getRoutesWithStops(setRoutes)
        getNonPlannedStops(setZip)
        console.log("Gotten")
    }, [alert])

    const addAllZips = (title) => {
        for(const e of activeZip){
            let active;
            if(e.cityName !== undefined){
                active = e.cityName;
            }else{
                active=e.shop
            }
            add(title, active).then(r => {getRoutesWithStops(setRoutes); getNonPlannedStops(setZip)})
        }
    }

    return(
        <View style={localStyle.outerContainer}>

            <View style={{flexDirection: "row", padding: 2, borderWidth: 2,}}>
                <SectionList
                    onLayout={onLayout}
                    sections={routes}
                    renderItem={( item ) =>{
                        let active;
                        let itemToRemove;
                        if(item.item.city !== undefined){
                            active = item.item.zip +", "+ item.item.city;
                            itemToRemove = item.item.city;
                        }else{
                            active= item.item.shop;
                            itemToRemove = item.item.shop;
                        }
                        return(
                        <TouchableOpacity style={{borderStyle: "dashed", borderWidth: 1, marginVertical: 2}} onPress={() => {
                                remove(itemToRemove).then(r => {getRoutesWithStops(setRoutes);getNonPlannedStops(setZip)});
                            }}>
                            <Text>{active}</Text>
                        </TouchableOpacity>)
                    }}
                    renderSectionHeader= {({section}) =>
                        <TouchableOpacity style={{backgroundColor: "white", borderWidth: 1, marginVertical: 2, minWidth: 100}}
                              onPress={() => {
                                  addAllZips(section.title)
                              }}>
                            <Text>{section.title}</Text>
                        </TouchableOpacity>
                    }/>
                <FlatList style={[localStyle.unassignedList, {minHeight: 100, maxHeight: unassignedZipsMax}]} data={zip} renderItem={({item}) => {
                    let active;
                    if(item.cityName !== undefined){
                        active = item.zip +", "+ item.cityName;
                    }else{
                        active= item.shop
                    }
                    return(
                    <TouchableOpacity
                        style={[localStyle.unassignedEntry, {backgroundColor: (activeZip.includes(item) ? "lime" : null)}]}
                        onPress={() => {
                            if(activeZip.includes(item)){
                                setActiveZip(activeZip.filter(e => e !== item))
                            } else {
                                setActiveZip([...activeZip, item])
                            }
                        }}>
                        <Text>{active}</Text>
                    </TouchableOpacity>)
                }}/>
            </View>
        </View>
    )
}

const localStyle = StyleSheet.create({
    title: {
        fontSize: 16,
    },
    unassignedEntry: {
        borderWidth: 1,
        borderStyle: "dashed",
        marginVertical: 2
    },
    unassignedList: {
        borderWidth: 1,
        padding: 2,
        margin: 1
    },
    outerContainer: {
        alignItems: "center",
        width: Platform.OS !== "web" ? "60%" : "",
        aspectRatio: Platform.OS !== "web" ? 50/29 : ""
    }


})
