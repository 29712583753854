import React, {useState} from 'react';
import {
    StyleSheet,
    View,
    Text,
    Button,
    TextInput,
    SafeAreaView,
    SectionList,
    FlatList,
    Platform,
    Alert, ScrollView
} from 'react-native';
import {MaterialIcons} from '@expo/vector-icons';
import { TouchableOpacity } from 'react-native';
import {AntDesign} from '@expo/vector-icons';
import Axios from "axios";
import {ipaddress} from "../../backend/backendConnection";
import Authorization from "../../backend/authorization";
import ShopPicker from "../../components/ShopPicker";
import platform from "react-native-web/dist/exports/Platform";

export default function PricingPage() {
    const [zipList, setZipList] = useState([])
    const [activeZip, setActiveZip] = useState(null)
    const [churchPrice, setChurchPrice] = useState("0")
    const [specialPrice, setSpecialPrice] = useState("0")
    const [activeShop, setActiveShop]  =useState(null)
    const [overallPrice, setOverallPrice] = useState("0")

    const [latestDelivery, setLatestDelivery] = useState({hour: "1", min: "00"})

    const getLatestDeliveryTime = (shop) => {
        Axios.post("https://"+ipaddress+"/getLatestDeliveryTime", {
                shop: shop
            },
            {
                headers: {
                    Authorization: Authorization.authdetails.authkey
                }
            }).then(r =>setLatestDelivery({hour: r.data.endTime.substr(0,2), min: r.data.endTime.substr(3,2)})).catch(err => console.log(err))
    }

    const updateLatestDeliveryTime = () => {
        Axios.post("https://"+ipaddress+"/changeLatestDeliveryTime", {
                shop: activeShop.name,
                hours: latestDelivery.hour,
                mins: latestDelivery.min
            },
            {
                headers: {
                    Authorization: Authorization.authdetails.authkey
                }
            }).then(r => getLatestDeliveryTime(activeShop.name)).catch(err => console.log(err))
    }

    const onShopPress = (shop) => {
        setActiveShop(shop)
        getLatestDeliveryTime(shop.name)
        Axios.post("https://"+ipaddress+"/getPricesForZips", {
                shop: shop.name
            },
            {
                headers: {
                    Authorization: Authorization.authdetails.authkey
                }
            }).then(r => setZipList(r.data)).catch(err => console.log(err))
        Axios.post("https://"+ipaddress+"/getUpchargePrices", {
                shop: shop.name,
            },
            {
                headers: {
                    Authorization: Authorization.authdetails.authkey
                }
            }).then(r => {
                for(const e of r.data){
                    if(e.description === "Church")
                        setChurchPrice(e.price)
                    else if(e.description === "Special")
                        setSpecialPrice(e.price)
                }
        }).catch(err => console.log(err))
    }
    const updatePrice = () => {
        console.log(activeZip)
        Axios.post("https://"+ipaddress+"/updateZipPrice", {
                shop: activeZip.shopName,
                price: activeZip.price,
                city: activeZip.cityName
            },
            {
                headers: {
                    Authorization: Authorization.authdetails.authkey
                }
            }).then(r => onShopPress(activeShop)).catch(err => console.log(err))
    }

    const updateUpchargePrices = () => {
        Axios.post("https://"+ipaddress+"/updateUpchargePrices", {
                shop: activeShop.name,
                churchPrice: churchPrice,
                specialPrice: specialPrice
            },
            {
                headers: {
                    Authorization: Authorization.authdetails.authkey
                }
            }).then(r => onShopPress(activeShop)).catch(err => console.log(err))
    }

    const executePriceUpdate = () => {
        Axios.post("https://"+ipaddress+"/setOverallPrice", {
                shop: activeShop.name,
            price: overallPrice
            },
            {
                headers: {
                    Authorization: Authorization.authdetails.authkey
                }
            }).then(r => onShopPress(activeShop)).catch(err => console.log(err))
    }

    const updateOverallPrice = () => {
        console.log(Platform.OS)
        if(Platform.OS !== "web") {
            Alert.alert(
                "Prisadvarel!",
                "Vil du gerne sætte alle postnumres pris til: " + overallPrice,
                [
                    {
                        text: "Ja",
                        onPress: () => executePriceUpdate(),
                    },
                    {
                        text: "Nej"
                    }
                ]
            )
        } else {
            executePriceUpdate()
        }
    }

    return(
        <View style={styles.container}>
            <View style={styles.section}>
                <ShopPicker onPress={onShopPress}/>
            </View>
            <View style={zipList.length > 0 ? [styles.section, styles.borderedView, styles.shadow] : [styles.section]}>
                <Text style={{fontSize: 18}}>{zipList.length > 0 ? zipList[0].shopName : ""}</Text>
                <FlatList data={zipList}
                          renderItem={({item}) => {
                    return(
                        <TouchableOpacity style={[styles.entry]} onPress={() => {setActiveZip(item)}}>
                            <Text>{item.toZip}, {item.cityName}, {item.price.toString()} kr.</Text>
                        </TouchableOpacity>
                    )
                }}/>
            </View>
            <ScrollView contentContainerStyle={{width: "100%"}}>
                { activeZip !== null ?
                    <View style={[styles.section, styles.borderedView]}>
                        <Text style={{fontSize: 15}}>Post nr. pris</Text>
                        <View style={styles.changePriceLine}>
                            <Text>Sæt pris til {activeZip.cityName}: </Text>
                            <TextInput value={activeZip.price.toString()}
                                       style={{width: 50, borderWidth: 1, borderRadius: 3}}
                                       onChangeText={text => setActiveZip({...activeZip, price: text})}/>
                            <Button title={"Opdater"} onPress={() => updatePrice()}/>
                        </View>
                    </View>
                    : null
                }
                <View style={[styles.section, styles.borderedView]}>
                    <Text style={{fontSize: 15}}>Masseopdater pris</Text>
                    <View style={styles.changePriceLine}>
                        <Text>Sæt alle postnumres pris til: </Text>
                        <TextInput value={overallPrice}
                                   style={{width: 50, borderWidth: 1, borderRadius: 3}}
                                   onChangeText={text => setOverallPrice(text)}/>
                        <Button title={"Opdater"} onPress={() => updateOverallPrice()}/>
                    </View>
                </View>
                <View style={[styles.section, styles.borderedView]}>
                    <Text style={{fontSize: 15}}>Seneste bestillings tid</Text>
                    <View style={styles.changePriceLine}>
                        <Text>T: </Text>
                        <TextInput style={styles.timeInput} maxLength={2} value={latestDelivery.hour} onChangeText={text => {setLatestDelivery({...latestDelivery, hour: text})}}/>
                        <Text>M: </Text>
                        <TextInput style={styles.timeInput} maxLength={2} value={latestDelivery.min} onChangeText={text => {setLatestDelivery({...latestDelivery, min: text})}}/>
                        <Button title={"Opdater"} onPress={() => updateLatestDeliveryTime()}/>
                    </View>
                </View>

                <View style={[styles.section, styles.borderedView, {maxHeight: "50%"}]}>
                    <Text style={{fontSize: 15}}>Tilkøbs pris</Text>
                    <View style={styles.changePriceLine}>
                        <Text>Sæt pris på kirkelevering: </Text>
                        <TextInput value={churchPrice.toString()}
                                   style={{width: 50, borderWidth: 1, borderRadius: 3}}
                                   onChangeText={text => setChurchPrice(text)}/>
                    </View>
                    <View style={styles.changePriceLine}>
                        <Text>Sæt pris på speciallevering: </Text>
                        <TextInput value={specialPrice.toString()}
                                   style={{width: 50, borderWidth: 1, borderRadius: 3}}
                                   onChangeText={text => setSpecialPrice(text)}/>
                    </View>
                    <Button title={"Opdater"} onPress={() => updateUpchargePrices()}/>
                </View>
            </ScrollView>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        alignItems: "center",
        backgroundColor: "white",
        height: "100%"
    },
    entry: {
        borderWidth: 1,
        borderColor: "lightgray",
        borderRadius: 3,
        marginTop: 3
    },
    section: {
        alignItems: "center",
        maxHeight: "25%",
        marginTop: 10
    },
    shadow: {
        shadowColor: '#171717',
        shadowOffset: {width: -2, height: 4},
        shadowOpacity: 0.2,
        shadowRadius: 3,
    },
    borderedView: {
        borderWidth: 1,
        borderColor: "lightgray",
        borderRadius: 4,
        padding: 3
    },
    changePriceLine: {flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginTop: 3},
    timeInput: {
        width: 50,
        borderWidth: 1,
        borderRadius:4
    }
})
