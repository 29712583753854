import React, {useState} from 'react';
import {StyleSheet, View, Text, Button, TextInput, Platform} from 'react-native';
import logins from '../backend/authenticator';
import Header from '../components/header';
import CryptoJS from 'crypto-js';
import Axios from 'axios';
import Authorization from '../backend/authorization';
import {ipaddress} from "../backend/backendConnection";
import styles from '../globalStyles/userTypeFrontPageStyle';

export default function Login({navigation}) {
    const [userName, setUserName] = useState('')
    const [password, setPassword] = useState('')
    const [loginMessage, setLoginMessage] = useState({text: '', color:'green'})

    const attemptLogOn = () => {
        Axios.post("https://"+ipaddress+"/login", {
            username: userName,
            password: CryptoJS.SHA1(password).toString()
        }).then((response) => {
                if(response.data !== false){
                    Authorization.authdetails = response.data[0];
                    switch(response.data[0].accesslevel){
                        case 'Shop':
                            navigation.navigate('ShopFrontPage')
                            break;
                        case 'Admin':
                            navigation.navigate('AdminFrontPage')
                            break
                        case 'Driver':
                            navigation.navigate('DriverFrontPage')
                            break
                    }
                    setLoginMessage({text: "Du er logget ind, siden loader vent venligts", color:'green'})
                    return
                }
                setLoginMessage({text: 'Det indtastede brugernavn eller password var ikke korrekt', color:'red'})
            }).catch(err => console.log(err));
    }
    return(
        <View style={styles.container}>
            <View style={localStyle.container}>
                <Text style={{color: loginMessage.color}}>{loginMessage.text}</Text>
                <View style={localStyle.line}>
                    <Text>Username:</Text>
                    <TextInput style={localStyle.textInput} placeholder='Username' defaultValue={userName} onChangeText={ newUserName => setUserName(newUserName)}/>
                </View>
                <View style={localStyle.line}>
                    <Text>Password:</Text>
                    <TextInput style={localStyle.textInput} secureTextEntry={true} placeholder='Password'
                    defaultValue={password} onChangeText={ newPassword => setPassword(newPassword)}/>
                </View>
                <Button title='Log in' onPress={attemptLogOn}/>
            </View>
        </View>
    );
}

const localStyle = StyleSheet.create({
    container: {
        margin: 10,
        borderWidth: 2,
        borderColor: 'black',
        alignItems: "center"
    },
    line:{
        marginTop:20,
        flexDirection: 'row',
    },
    textInput:{
        overflow: 'hidden',
    },
})
