import React, {useEffect, useState} from 'react';
import { StyleSheet, View, Text, Button, TextInput, SafeAreaView, SectionList,FlatList } from 'react-native';
import FlowerView from "../../components/flowerView";
import Axios from "axios";
import Authorization from "../../backend/authorization";
import DatePicker from "../../components/datePicker";
import styles from '../../globalStyles/userTypeFrontPageStyle';
import {ipaddress} from "../../backend/backendConnection";
import DropDownPicker from "react-native-dropdown-picker";
import {getShopsForDropDown} from "../../backend/datafunctions";

export default function FlowerOverview({navigation}){
    const [flowers, setFlowers] = useState([])
    const date = new Date();

    const [fromDate, setFromDate] = useState({day: date.getDate(), month: date.getMonth() + 1, year: date.getFullYear()})
    const [toDate, setToDate] = useState({day: date.getDate(), month: date.getMonth() + 1, year: date.getFullYear()})
    const [open, setOpen] = useState(false)
    const [shop, setShop] = useState("Alle")
    const [shopList, setShopList] = useState([])
    const getFlowers = () => {
        Axios.post("https://"+ipaddress+"/getFlowers", {
                shop: shop,
                from: fromDate,
                to: toDate
            },
            {
                headers: {
                    Authorization: Authorization.authdetails.authkey
                }
            }).then(res => {
                console.log(res.data)
            if (res.data.length > 0)
                setFlowers(res.data)
        }).catch(err => console.log(err))
    }

    useEffect(() => {
        getFlowers()
        getShopsForDropDown(setShopList)
    }, [])
    useEffect(() => {
        setToDate(fromDate)
    }, [fromDate])
    return(
        <View style={styles.container}>
            <Text style={{fontSize: 24}}>Bestilte leveringer</Text>
            <View style={{alignItems: "center"}}>
                <DropDownPicker
                    open={open}
                    value={shop}
                    items={shopList}
                    setOpen={setOpen}
                    setValue={setShop}
                    setItems={setShopList}
                />
                <View style={{flexDirection: "row", justifyContent: "flex-end", alignItems: "center"}}>
                    <Text>Fra: </Text>
                    <DatePicker source={fromDate} setSource={setFromDate}/>
                    <Text> Til: </Text>
                    <DatePicker source={toDate} setSource={setToDate}/>
                    <Button title={"Søg"} onPress={() => getFlowers()}/>
                </View>
                <FlowerView navigation={navigation} data={flowers} reloadFunction={getFlowers}/>
            </View>
        </View>
    )
}



