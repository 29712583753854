import React, {useState} from 'react';
import {StyleSheet, View, Text, Button, TextInput, Platform, TouchableOpacity} from 'react-native';
import CryptoJS from 'crypto-js';
import Axios from 'axios';
import styles from '../../globalStyles/userTypeFrontPageStyle';

export default function DriverFrontPage({navigation}){
    return(
        <View style={styles.container}>
            <View style={styles.choiseLine}>
                <TouchableOpacity style={styles.choiseButton} onPress={() => navigation.navigate('MorningRoutePage')}>
                    <Text>Morgen-</Text>
                    <Text>route</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.choiseButton} onPress={() => navigation.navigate('AfternoonRoutePage')}>
                    <Text>Eftermiddag-</Text>
                    <Text>route</Text>
                </TouchableOpacity>
            </View>
            <View style={styles.choiseLine}>
                <TouchableOpacity style={styles.choiseButton} onPress={() => navigation.navigate('WorkingDays')}>
                    <Text>Skema</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.choiseButton} onPress={() => navigation.navigate('OrderFlowerPage')}>
                    <Text>Tilføj-</Text>
                    <Text>blomst</Text>
                </TouchableOpacity>
            </View>
            <View style={styles.choiseLine}>
                <TouchableOpacity style={styles.choiseButton} onPress={() => navigation.navigate('MorningNavigationPage')}>
                    <Text>Morgen-</Text>
                    <Text>Plan</Text>
                </TouchableOpacity>
            </View>
        </View>
    )
}
