import Axios from "axios";
import Authorization from "./authorization";
import {ipaddress} from "./backendConnection";

export const getAfternoonRoutes = (setVariable) => {
    Axios.post("https://"+ipaddress+"/getAfternoonRoutes",{
        shop: Authorization.authdetails.referencinguser,
    },{
        headers: {
            Authorization: Authorization.authdetails.authkey
        }
    }).then(r => {setVariable(r.data)})
}

export const addAfternoonRoute = (routeName, setMessage) => {
    Axios.post("https://"+ipaddress+"/addAfternoonRoute",{
        shop: Authorization.authdetails.referencinguser,
        name: routeName
    },{
        headers: {
            Authorization: Authorization.authdetails.authkey
        }
    }).then(r => {
        if(!r.data){
            setMessage("Ruten kunne ikke tilføjes")
        }else {
            setMessage("")
        }
    })
}

export const deleteAfternoonRoute = (routeName, setMessage) => {
    Axios.post("https://"+ipaddress+"/deleteAfternoonRoute",{
        shop: Authorization.authdetails.referencinguser,
        name: routeName
    },{
        headers: {
            Authorization: Authorization.authdetails.authkey
        }
    }).then(r => {
        if(!r.data){
            setMessage("Ruten kunne ikke slettes")
        }else {
            setMessage("Ruten blev slettet")
        }
    })
}

export const getMorningRoutes = (setVariable) => {
    Axios.post("https://"+ipaddress+"/getMorningRoutes",{
        shop: Authorization.authdetails.referencinguser,
    },{
        headers: {
            Authorization: Authorization.authdetails.authkey
        }
    }).then(r => {setVariable(r.data)})
}

export const addMorningRoute = (routeName, setMessage) => {
    Axios.post("https://"+ipaddress+"/addMorningRoute",{
        shop: Authorization.authdetails.referencinguser,
        name: routeName
    },{
        headers: {
            Authorization: Authorization.authdetails.authkey
        }
    }).then(r => {
        if(!r.data){
            setMessage("Ruten kunne ikke tilføjes")
        }else {
            setMessage("")
        }
    })
}

export const deleteMorningRoute = (routeName, setMessage) => {
    Axios.post("https://"+ipaddress+"/deleteMorningRoute",{
        shop: Authorization.authdetails.referencinguser,
        name: routeName
    },{
        headers: {
            Authorization: Authorization.authdetails.authkey
        }
    }).then(r => {
        if(!r.data){
            setMessage("Ruten kunne ikke slettes")
        }else {
            setMessage("Ruten blev slettet")
        }
    })
}

export const getApprovedShops = (setShops) => {
    Axios.post("https://"+ipaddress+"/approvedShops",{
        shop: Authorization.authdetails.referencinguser,
    },{
        headers: {
            Authorization: Authorization.authdetails.authkey
        }
    }).then(r => {
        if(r.data === false)
            return
        setShops(r.data)
    })
}

export const getAfternoonRoutesWithZip = (setValue) => {
    Axios.post("https://"+ipaddress+"/afternoonRoutesWithZip",{
        shop: Authorization.authdetails.referencinguser,
    },{
        headers: {
            Authorization: Authorization.authdetails.authkey
        }
    }).then(r => {
        if(r.data === false)
            return
        setValue(r.data)
    })
}

export const getZipsNotOnRoute= (setValue) => {
    Axios.post("https://"+ipaddress+"/zipsNotOnRoute",{
        shop: Authorization.authdetails.referencinguser,
    },{
        headers: {
            Authorization: Authorization.authdetails.authkey
        }
    }).then(r => {
        if(r.data === false)
            return
        setValue(r.data)
    })
}

export const addZipToRoute = (route, city) => {
    return Axios.post("https://"+ipaddress+"/addZipToRoute",{
        shop: Authorization.authdetails.referencinguser,
        route: route,
        city: city
    },{
        headers: {
            Authorization: Authorization.authdetails.authkey
        }
    })
}

export const removeZipFromRoute = (city) => {
    return Axios.post("https://"+ipaddress+"/removeZipFromRoute",{
        shop: Authorization.authdetails.referencinguser,
        city: city
    },{
        headers: {
            Authorization: Authorization.authdetails.authkey
        }
    })
}

export const getMorningRoutesWithShops = (setValue) => {
    Axios.post("https://"+ipaddress+"/morningRoutesWithShop",{
        shop: Authorization.authdetails.referencinguser,
    },{
        headers: {
            Authorization: Authorization.authdetails.authkey
        }
    }).then(r => {
        if(r.data === false)
            return
        setValue(r.data)
    })
}

export const getShopsNotOnRoute= (setValue) => {
    Axios.post("https://"+ipaddress+"/shopsNotOnRoute",{
        shop: Authorization.authdetails.referencinguser,
    },{
        headers: {
            Authorization: Authorization.authdetails.authkey
        }
    }).then(r => {
        console.log(r.data)
        if(r.data === false)
            return;
        setValue(r.data)
    })
}

export const addShopToRoute = (route, shop) => {
    return Axios.post("https://"+ipaddress+"/addShopToRoute",{
        route: route,
        shop: shop
    },{
        headers: {
            Authorization: Authorization.authdetails.authkey
        }
    })
}

export const deleteShopFromRoute = (shop) => {
    return Axios.post("https://"+ipaddress+"/deleteShopFromRoute",{
        shop: shop
    },{
        headers: {
            Authorization: Authorization.authdetails.authkey
        }
    })
}

export const changeDeliveryState = (item, newState, reloadFunction) => {
    Axios.post("https://"+ipaddress+"/setDeliveryState", {
            ...item,
            newState: newState
        },
        {
            headers: {
                Authorization: Authorization.authdetails.authkey
            }
        }).then(r=> reloadFunction())

}

export const getShops = (setState) => {
    Axios.get("https://"+ipaddress+"/shops").then(r=> setState(r.data))

}


export const getShopsForDropDown = (setState) => {
    Axios.get("https://"+ipaddress+"/shops",{
        headers: {
            Authorization: Authorization.authdetails.authkey
        }
    }).then(res => {
        let tempShops = []
        tempShops.push({label: "Alle", value: "Alle"})
        for(const e of res.data){
            tempShops.push({label: e.name, value: e.name})
        }
        console.log(tempShops)

        setState(tempShops)
    })
}
export const getShopsForDropDownWithOutAll = (setState) => {
    Axios.get("https://"+ipaddress+"/shops",{
        headers: {
            Authorization: Authorization.authdetails.authkey
        }
    }).then(res => {
        let tempShops = []
        for(const e of res.data){
            tempShops.push({label: e.name, value: e.name})
        }
        console.log(tempShops)

        setState(tempShops)
    })
}


export const countFlowers = (flowers) => {
    let total = 0;

    for(const e of flowers) {
        total += e.count;
    }
    return total;
}
