import React, {Component, useEffect, useState} from "react";
import { View, FlatList,Text, TextInput, Button, StyleSheet, TouchableOpacity, BackHandler} from "react-native";
import styles from '../../globalStyles/userTypeFrontPageStyle';
import Axios from "axios";
import Authorization from "../../backend/authorization";
import FlowerView from "../../components/flowerView";
import {ipaddress} from "../../backend/backendConnection";


export default function ShopFlowerOverview({navigation}){
    const [flowers, setFlowers] = useState([])
    let date = new Date();
    const [fromDate, setFromDate] = useState({day: date.getDate().toString(), month: (date.getMonth() +1).toString(), year: date.getFullYear().toString()})
    const [toDate, setToDate] = useState({day: (date.getDate()).toString(), month: ((date.getMonth() +1)).toString(), year: (date.getFullYear()).toString()})


    const getFlowers = () => {
        Axios.post("https://"+ipaddress+"/getFlowers", {
                shop: Authorization.authdetails.referencinguser,
                from: fromDate,
                to: toDate
            },
            {
                headers: {
                    Authorization: Authorization.authdetails.authkey
                }
            }).then(res => {
                console.log(res.data)
            switch (res.data){
                case 13:
                    break
                case 10:
                    break;
                default: {
                    for(const e of res.data) {
                        e.data.forEach((ee, ii) => {
                            ee.shop = Authorization.authdetails.referencinguser;
                        })
                    }
                    setFlowers(res.data)

                }
            }}).catch(err => console.log(err))
    }
    useEffect(()=> {
        getFlowers()
    },[])
    return(
        <View style={styles.container}>
            <View style={localStyle.datePicker}>
                <Text style={{marginRight: 20}}>Vælg tidsinterval:</Text>
                <View style={{alignItems:"center", flexDirection: "row"}}>
                <Text>Fra:</Text>
                <TextInput style={localStyle.dayMonthInput} maxLength={2} value={fromDate.day} placeholder={"DD"} onChangeText={(text) =>setFromDate({...fromDate, day: text})}/>
                <Text>/</Text>
                <TextInput style={localStyle.dayMonthInput} maxLength={2} value={fromDate.month} placeholder={"MM"} onChangeText={(text) => setFromDate({...fromDate, month: text})}/>
                <Text>/</Text>
                <TextInput style={localStyle.yearInput} maxLength={4} value={fromDate.year} placeholder={"YY"} onChangeText={(text) => setFromDate({...fromDate, year: text})}/>
                <Text>   Til:</Text>
                <TextInput style={localStyle.dayMonthInput} maxLength={2} value={toDate.day} placeholder={"DD"} onChangeText={(text) => setToDate({...toDate, day: text})}/>
                <Text>/</Text>
                <TextInput style={localStyle.dayMonthInput} maxLength={2} value={toDate.month} placeholder={"MM"} onChangeText={(text) => setToDate({...toDate, month: text})}/>
                <Text>/</Text>
                <TextInput style={localStyle.yearInput} maxLength={4} value={toDate.year} placeholder={"YY"} onChangeText={(text) => setToDate({...toDate, year: text})}/>
                </View>
                <Button title={"Søg"} onPress={() => getFlowers()}/>
            </View>
            <FlowerView data={flowers} navigation={navigation} reloadFunction={getFlowers}/>
        </View>
    )
}

const localStyle = StyleSheet.create({
    datePicker: {
        alignItems: "center"
    },
    dayMonthInput: {
        width: 20
    },
    yearInput: {
        width: 30
    }
})
