import React, { useEffect, useState } from "react";
import { View, Button, Text, StyleSheet, FlatList, ScrollView, TouchableOpacity} from "react-native";
import Axios from "axios";
import Authorization from "../../backend/authorization";
import {ipaddress} from "../../backend/backendConnection";
import {AntDesign} from "@expo/vector-icons";

export default function ModifyMorningRoutes({ navigation}){
    const {shop, date} = navigation.state.params;
    const [flowers,setFlowers] = useState([])
    const loadFlowers = () => {
        Axios.post("https://"+ipaddress+"/getFlowersForShop", {
                shop: shop,
                date: date,
                time: 0
            },
            {
                headers: {
                    Authorization: Authorization.authdetails.authkey
                }
            }).then(r => {if(r.data !== false){setFlowers(r.data)}})
    }

    const checkFlower = (item) => {
        console.log(item)
        Axios.post("https://"+ipaddress+"/setCollected", {
                ...item
            },
            {
                headers: {
                    Authorization: Authorization.authdetails.authkey
                }
            }).then(r => {if(r.data !== false){loadFlowers()}})
    }
    useEffect(() => {loadFlowers()}, [])
    const Flower = ({item}) => {
        const address = item.street+", "+item.number+","+item.city+", "+item.zip + ((item.churchDelivery === 1 && item.afternoonChurchDelivery === 0) ? ", "+ item.churchName : "")
            + (item.count > 1 ? "*" : "")
        return(
            <View style={{flexDirection: "row"}}>
                <Text style={{width: "50%"}}>{address}</Text>
                <Text style={{width: "16%"}}>{item.description === null ? "" : "Ja"}</Text>
                <TouchableOpacity style={{width: "16%"}} onPress={() => navigation.navigate('FlowerEditor', {item, loadFlowers})}>
                    <AntDesign name='edit' size={20}/>
                </TouchableOpacity>
                <TouchableOpacity style={{width: "16%"}} onPress={() => {checkFlower(item); item.collected = item.collected === 1 ? 0 : 1; }}>
                    <AntDesign name = { item.collected === 1 ? 'check' : 'close'} size={20}/>
                </TouchableOpacity>
            </View>
        )
    }

    const Header = () => {
        return(
            <View style={{flexDirection: "row", backgroundColor: "white"}}>
                <Text style={{width: "50%"}}>Adresse</Text>
                <Text style={{width: "16%"}}>Spc. lev.</Text>
                <Text style={{width: "16%"}}>Rediger</Text>
                <Text style={{width: "16%"}}>Afhentet</Text>
            </View>
        )
    }
    return(
        <View style={localStyle.container}>
            <Text style={localStyle.header}>Blomster</Text>
            <FlatList data={flowers}
                      style={localStyle.listStyle}
                      ListHeaderComponent={() => <Header/>}
                      renderItem={({item}) => <Flower item={item}/>}/>
        </View>
    )
}

const localStyle = StyleSheet.create({
    addressEntry:{
        flexDirection: "row"
    },
    listStyle: {
        width: "100%",
    },
    container: {
        alignItems: "center"
    },
    header: {
        fontSize: 20
    }

})
