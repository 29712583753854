import React, {Component, useEffect, useState} from "react";
import { View, FlatList,Text, TextInput, Button, StyleSheet, TouchableOpacity, BackHandler} from "react-native";
import styles from '../globalStyles/userTypeFrontPageStyle';

export default function DatePicker({source, setSource, style}){


    return(
        <View style={{flexDirection: "row"}}>
            <TextInput style={[localStyle.dayMonthInput, style]} maxLength={2} value={source.day.toString()} placeholder={"DD"} onChangeText={(text) =>setSource({...source, day: text})}/>
            <Text>/</Text>
            <TextInput style={[localStyle.dayMonthInput, style]} maxLength={2} value={source.month.toString()} placeholder={"MM"} onChangeText={(text) => setSource({...source, month: text})}/>
            <Text>/</Text>
            <TextInput style={[localStyle.yearInput, style]} maxLength={4} value={source.year.toString()} placeholder={"YY"} onChangeText={(text) => setSource({...source, year: text})}/>
        </View>
    )
}

const localStyle = StyleSheet.create({
    datePicker: {
        flexDirection: "row"
    },
    dayMonthInput: {
        width: 30
    },
    yearInput: {
        width: 40
    }
})
