import {useEffect} from "react";
import React, {useState} from 'react';
import { StyleSheet, View, Text, Button, TextInput, SafeAreaView, SectionList,FlatList } from 'react-native';
import {MaterialIcons} from '@expo/vector-icons';
import { TouchableOpacity } from 'react-native';
import {AntDesign} from '@expo/vector-icons';
import Axios from "axios";
import {ipaddress} from "../backend/backendConnection";
import Authorization from "../backend/authorization";

export default function ShopPicker({onPress}){
    const [shops, setShops] = useState([])
    const [activeItem, setActiveItem] = useState({})
    const loadShops = () => {
        Axios.get("https://"+ipaddress+"/shops").then(r => setShops(r.data)).catch(err => console.log(err))

    }
    useEffect(() => {
        loadShops()
    }, [])
    return(
        <View style={[styles.container, styles.shadow]}>
            <Text style={styles.title}>Butikker</Text>
            <FlatList  data={shops} renderItem={({item}) => {
                return(
                  <TouchableOpacity style={[styles.entry, {backgroundColor: activeItem === item ? 'aqua' : null}]} onPress={() => {setActiveItem(item);onPress(item)}}>
                      <Text>{item.name}</Text>
                  </TouchableOpacity>
                )
            }}/>
        </View>
    )
}

const styles = StyleSheet.create({
    title: {
        fontSize: 18,
        textDecorationLine: "underline"
    },
    container: {
        borderWidth: 1,
        borderColor: "lightgray",
        borderRadius: 4,
        padding: 4,
        alignItems: "center",
        height: "100%"
    },
    entry: {
        borderWidth: 1,
        borderColor: "lightgray",
        borderRadius: 3,
        marginTop: 3
    },
    shadow: {
        shadowColor: '#171717',
        shadowOffset: {width: -2, height: 4},
        shadowOpacity: 0.2,
        shadowRadius: 3,
    }
})
