import React, { useEffect, useState } from "react";
import {View, Button, Text, StyleSheet, FlatList, SafeAreaView, ScrollView} from "react-native";
import styles from '../../globalStyles/userTypeFrontPageStyle';
import Axios from "axios";
import TouchableDriverEntry from "./touchableDriverEntry";
import DriversWorkingCalendar from "./driversWorkingCalendar";
import {ipaddress} from "../../backend/backendConnection";
export default function DriverWorkingDaysOverview(){


    const [driverData, setDriverData] = useState([]);
    const cud = new Date();
    const [week, setWeek] = useState(1);
    const [year, setYear] = useState(cud.getFullYear()%2000);
    const [activeDriver, setActiveDriver] = useState();
    useEffect(() =>{
        setWeek(getWeekNumber(new Date())[1])
        Axios.get("https://"+ipaddress+"/drivers").then((response) => {
            setDriverData(response.data);
    });}, [])


    const driverPressHandler = (item) => {
        console.log(item)
        if(item === activeDriver){
            setActiveDriver(undefined)
        }else{
            setActiveDriver(item)
        }
    }

    const addDriverPressHandler = (day, section, updateFunction) => {
        console.log(activeDriver, year, week, day, section)
        Axios.post("https://"+ipaddress+"/addWork/"+activeDriver+"/"+year+"/"+week+"/"+day+"/"+section).then(() => updateFunction()).catch(err => console.log(err))
    }
    function getWeekNumber(d) {
        // Copy date so don't modify original
        d = new Date(+d);
        d.setHours(0, 0, 0, 0);
        // Set to nearest Thursday: current date + 4 - current day number
        // Make Sunday's day number 7
        d.setDate(d.getDate() + 4 - (d.getDay() || 7));
        // Get first day of year
        var yearStart = new Date(d.getFullYear(), 0, 1);
        // Calculate full weeks to nearest Thursday
        var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7)
        // Return array of year and week number
        return [d.getFullYear(), weekNo];
    }
    function weeksInYear(year) {
        var d = new Date(year, 11, 31);
        var week = getWeekNumber(d)[1];
        return week === 1 ? 52 : week;
    }
    return(
        <View style={localStyle.root}>
            <View style={localStyle.weekContainer}>
                <Text style={{fontSize:24}}>År: {year}</Text>
            </View>
            <View style={localStyle.weekContainer}>
                <Button title="<" onPress={() => {
                    if(week === 1){
                        setYear(year-1)
                        if(weeksInYear(year) === 53)
                            setWeek(53)
                        else
                            setWeek(52)
                        return
                    }
                    setWeek(week-1)
                    }
                }/>
                <Text style={localStyle.weekTextFont}>Uge: {week}</Text>
                <Button title=">" onPress={() => {
                    if(week === weeksInYear(year)){
                        setYear(year+1)
                        setWeek(1)
                        return
                    }
                    setWeek(week+1)
                    }
                }/>
            </View>
           <ScrollView contentContainerStyle={localStyle.calendarDriverArea}>
                <DriversWorkingCalendar pressHandler={addDriverPressHandler} year={year%2000} week={week}/>
            </ScrollView>
            <View style={localStyle.calendarDriverArea}>
                <FlatList data={driverData} keyExtractor={(item, index) => item.DriverID} renderItem={({item}) => (
                 <TouchableDriverEntry pressHandler={driverPressHandler} data={item} activeDriver={activeDriver}/>)}/>
            </View>
        </View>
    )
}




const localStyle = StyleSheet.create({
    root: {
        flex:1,
        backgroundColor: "white"
    },
    weekContainer: {
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row"
    },
    weekTextFont: {
        fontSize: 24,
        marginHorizontal: 3
    },
    calendarDriverArea:{
        alignItems: "center",
    }
})
