import {useEffect, useState} from "react";
import {Button, FlatList, Linking, Platform, TouchableOpacity, View, Text} from "react-native";
import newGlobalStyles from "../../globalStyles/newGlobalStyles";
import Axios from "axios";
import Authorization from "../../backend/authorization";
import {ipaddress} from "../../backend/backendConnection";
import DatePicker from "../../components/datePicker";
export default function MorningNavigationPage(){
    const [stops, setStops] = useState([])
    const [active, setActive] = useState({})
    const d = new Date();
    const [date, setDate] = useState({day: d.getDate(), month: d.getMonth()+1, year: d.getFullYear()%2000})
    useEffect(() => {
        Axios.post("https://"+ipaddress+"/getAllDriverStopAddressesOnMorningRoute", {
            date: date,
            driverID: Authorization.authdetails.referencinguser
        }, {
            headers: {
                Authorization: Authorization.authdetails.authkey
            }
        }).then(r => {
            if (r.data && r.data !== false) {
                setStops(r.data)
                console.log(r.data)
            }
        }).catch(err => console.log(err))
    }, [date])

    return(
        <View style={newGlobalStyles.container}>
            <DatePicker source={date} setSource={setDate}/>
            <FlatList data={stops} renderItem={({item}) => {
                return(
                    <TouchableOpacity onPress={() => setActive(item)} style={[newGlobalStyles.listEntry, {backgroundColor: active === item ? "aqua" : "white"}]}>
                        <Text>{item}</Text>
                    </TouchableOpacity>
                )
            }}/>
            <View style={{marginBottom: 20}}>
            <Button title={"Naviger"} onPress={() => {
                console.log(active)
                if(!active){
                    return
                }
                const scheme = Platform.select({ ios: 'maps:0,0?q=', android: 'geo:0,0?q=' });
                const label = 'Custom Label';
                const address = active
                const url = Platform.select({
                    ios: `${scheme}${address}`,
                    android: `${scheme}${address}`
                });
                Linking.openURL(url);
            }}/>
            </View>
        </View>
    )
}
