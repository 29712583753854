import React, {Component, useEffect, useRef, useState} from "react";
import {
    View,
    FlatList,
    Text,
    TextInput,
    Button,
    StyleSheet,
    TouchableOpacity,
    BackHandler,
    SectionList, Platform, ScrollView, YellowBox, LogBox, SafeAreaView, Switch
} from "react-native";
import styles from '../../globalStyles/userTypeFrontPageStyle';
import Axios from "axios";
import Authorization from "../../backend/authorization";
import DatePicker from "../../components/datePicker";
import RoutesList from "../../components/RoutesList";
import {
    addAfternoonRoute, addMorningRoute, addShopToRoute, addZipToRoute,
    deleteAfternoonRoute, deleteMorningRoute, deleteShopFromRoute,
    getAfternoonRoutes, getAfternoonRoutesWithZip, getApprovedShops,
    getMorningRoutes, getMorningRoutesWithShops, getShopsNotOnRoute, getZipsNotOnRoute, removeZipFromRoute,
} from "../../backend/datafunctions";
import RouteConfiguration from "../../components/routeConfiguration";

export default function RouteControlPage(){
    const [afternoonRoutes, setAfternoonRoutes] = useState([])
    const [morningRoutes, setMorningRoutes] = useState([])
    const [showMorningRoutes, setShowMorningRoutes] = useState(true)
    const [changeAlert, setChangeAlert] = useState(true)
    useEffect(()=> {
        getMorningRoutesWithShops(setMorningRoutes)
        getAfternoonRoutesWithZip(setAfternoonRoutes)
    }, [])
    const renderRoutes = () => {
            if(Platform.OS !== "web") {
                return(
                <View style={{alignItems: "center", height: "100%"}}>
                    <View style={{flexDirection: "row", justifyContent: "center"}}>
                        <Text>Morgen</Text>
                        <Switch value={showMorningRoutes}
                                onValueChange={() => {setShowMorningRoutes(!showMorningRoutes); setChangeAlert(!changeAlert)}}/>
                        <Text>Eftermiddag</Text>
                    </View>
                    <View style={{height: "10%"}}>
                {
                     !showMorningRoutes ?
                        <RouteConfiguration add={addShopToRoute} remove={deleteShopFromRoute}
                                            getRoutesWithStops={getMorningRoutesWithShops}
                                            getNonPlannedStops={getShopsNotOnRoute}
                                            routes={morningRoutes}
                                            title={"Morgen"}
                                            alert={changeAlert}
                                            setRoutes={setMorningRoutes}/>
                        :
                        <RouteConfiguration add={addZipToRoute} remove={removeZipFromRoute}
                                            getRoutesWithStops={getAfternoonRoutesWithZip}
                                            getNonPlannedStops={getZipsNotOnRoute}
                                            routes={afternoonRoutes}
                                            title={"Eftermiddag"}
                                            alert={changeAlert}
                                            setRoutes={setAfternoonRoutes}/>
                }
                    </View>
                </View>
                    )


        } else {
                return (
                <View style={{flexDirection: "row",}}>
                    <RouteConfiguration add={addShopToRoute} remove={deleteShopFromRoute}
                                        getRoutesWithStops={getMorningRoutesWithShops}
                                        getNonPlannedStops={getShopsNotOnRoute}
                                        routes={morningRoutes}
                                        title={"Morgen"}
                                        alert={changeAlert}
                                        setRoutes={setMorningRoutes}/>
                    <RouteConfiguration add={addZipToRoute} remove={removeZipFromRoute}
                                        getRoutesWithStops={getAfternoonRoutesWithZip}
                                        getNonPlannedStops={getZipsNotOnRoute}
                                        routes={afternoonRoutes}
                                        alert={changeAlert}
                                        title={"Eftermiddag"}
                                        setRoutes={setAfternoonRoutes}/>
                </View>
            )
        }
    }
    return (
        <View style={{alignItems: "center", flex: 1}}>
            <Text style={localStyle.title}>Ruteplanlæggeren</Text>
            <View style={{flexDirection: "row", marginVertical: 3}}>
                <RoutesList dataFunction={getMorningRoutes}
                            addFunction={addMorningRoute}
                            deleteFunction={deleteMorningRoute}
                            onChange={() => {
                                getMorningRoutesWithShops(setMorningRoutes)
                                setChangeAlert(!changeAlert)
                            }}
                            title={"Morgen"}/>
                <RoutesList dataFunction={getAfternoonRoutes}
                            addFunction={addAfternoonRoute}
                            deleteFunction={deleteAfternoonRoute}
                            onChange={() => {
                                getAfternoonRoutesWithZip(setAfternoonRoutes);
                                setChangeAlert(!changeAlert)
                            }}
                            title={"Eftermiddag"}/>

            </View>
            {renderRoutes()}
        </View>
    )
}

const localStyle = StyleSheet.create({
    title: {
        fontSize: 24,
    },



})
