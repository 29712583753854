import React, {Component, useState} from "react";
import { View, FlatList,Text, TextInput, Button, StyleSheet, TouchableOpacity, Alert } from "react-native";
import {MaterialIcons} from '@expo/vector-icons';
import styles from '../../globalStyles/userTypeFrontPageStyle';

export default function NameEntry({pressHandler, data}){
    return(
        <View style={localStyle.nameLine}>
            <Text style={styles.choiseLine}>{data.DriverName}</Text>
            <TouchableOpacity onPress={() => pressHandler(data.DriverID)}>
                <MaterialIcons name='delete' size={30} />
            </TouchableOpacity>
        </View>
    )
}
const localStyle = StyleSheet.create({
    nameLine:{
    margin:5,
    flexDirection: 'row'
    }
})
