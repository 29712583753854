import React, {Component, Fragment, useEffect, useRef, useState} from "react";
import {
    View,
    FlatList,
    Text,
    TextInput,
    Button,
    StyleSheet,
    TouchableOpacity,
    BackHandler,
    ScrollView, Platform
} from "react-native";
import styles from '../../globalStyles/userTypeFrontPageStyle';
import Axios from "axios";
import Authorization from "../../backend/authorization";
import {ipaddress} from "../../backend/backendConnection";
import DatePicker from "../../components/datePicker";
import DropDownPicker from "react-native-dropdown-picker";
import DropdownTextInput from "../../components/DropdownTextInput";

export default function OrderFlowerPage(){
    const [church, setChurch] = useState(0)
    const [churchName, setChurchName] = useState('')
    const [churchDeliveryH, setChurchDeliveryH] = useState('')
    const [churchDeliveryM, setChurchDeliveryM] = useState('')
    const [street, setStreet] = useState('')
    const [buildingNumber, setBuildingNumber] = useState('')
    const [zip, setZip] = useState('')
    const [city, setCity] = useState('')
    const [day, setDay] = useState('')
    const [month, setMonth] = useState('')
    const [year, setYear] = useState('')
    const [special, setSpecial] = useState(1)
    const [specialDesc, setSpecialDesc] = useState('')
    const [pickedUpFromShop, setPickedUpFromShop] = useState(true)
    const [receiver, setReceiver] = useState('')
    const [receiverPhone, setReceiverPhone] = useState('')
    const [flowerCount, setFlowerCount] = useState('1')
    const [response, setResponse] = useState({message:'', color:'red'})
    const [price, setPrice] = useState('')
    const nextFromZip = useRef()
    const nextFromReceiverPhone = useRef()
    const nextFromDay = useRef()
    const [latestDeliveryTime, setLatestDeliveryTime] = useState("")
    const curDate= new Date();
    const [pickUpDate, setPickUpDate] = useState({day: curDate.getDate().toString(), month: (curDate.getMonth()+1).toString(), year: (curDate.getFullYear()%2000).toString()})
    const [open, setOpen] = useState(false);
    const [shop, setShop] = useState(null);
    const [shopList, setShopList] = useState([]);
    const numberRef = useRef()

    const isAdminOrDriver = () => {
        return Authorization.authdetails.referencinguser === "Admin" ||(Authorization.authdetails.referencinguser && Authorization.authdetails.referencinguser.match(/^[0-9]+$/));
    }


    useEffect(() => {
        setYear(pickUpDate.year)
        setMonth(pickUpDate.month)
        setDay(pickUpDate.day)
    }, [pickUpDate])
    useEffect(() => {
        const year = curDate.getFullYear().toString();
        setYear(year.substr(2,2));
        getLatestDelivery()
        Axios.get("https://"+ipaddress+"/shops",{
            headers: {
                Authorization: Authorization.authdetails.authkey
            }
        }).then(res => {
            let tempShops = []
            for(const e of res.data){
                tempShops.push({label: e.name, value: e.name})
            }
            setShopList(tempShops)
        })

    }, [])
    useEffect(() => {
        Axios.post("https://"+ipaddress+"/calculateDeliveryPrice", {
            shop: shop ? shop : Authorization.authdetails.referencinguser,
            zip: zip,
            church: church === 1,
            special: special === 0,
            count: flowerCount
        },{
            headers: {
                Authorization: Authorization.authdetails.authkey
            }
        }).then(res => {console.log(res.data); if(!isNaN(res.data)) setPrice(res.data)}).catch(err => setPrice(''))
    }, [zip, church, special, flowerCount])

   const orderFlower = () => {

        if(church === 1 && (churchDeliveryM.length === 0 || churchDeliveryH.length === 0)){
            setResponse({message: "Indtast starttid for ceremoni", color: "red"})
        }
        Axios.post("https://"+ipaddress+"/addOrder", {
            shop: shop ? shop : Authorization.authdetails.referencinguser,
            street: street,
            number: buildingNumber,
            zip: zip,
            city: city,
            day: day,
            month: month,
            year: year,
            church: church,
            churchName: churchName,
            churchDeliveryH: churchDeliveryH,
            churchDeliveryM: churchDeliveryM,
            special: special,
            specialDesc: specialDesc,
            pickedUpFromShop: pickedUpFromShop,
            receiver: receiver,
            rp: receiverPhone,
            count: flowerCount,
            pickUpDate: pickUpDate
        }, {
            headers: {
                Authorization: Authorization.authdetails.authkey
            }
        }).then(res => {
            console.log(res.data)
            switch (res.data){
                case 10:
                    setResponse({message: "Der er sket en fejl prøv at genstarte applikationen", color:"red"})
                    break;
                case 11:
                    setResponse({message: "Leverings datoen er ikke gyldig", color:"red"})
                    break;
                case 12:
                    setResponse({message: "Det valgte tidspunkt er ikke gyldigt", color:"red"})
                    break;
                case 13:
                    setResponse({message: "Prisen kunne ikke beregnes, tjek at dine data er korrekte, der sandsynligvis mangler et post nr. ellers er der ikke valgt antal blomster", color:"red"})
                    break;
                case 14:
                    setResponse({message: "Vejnavn, nummer eller by er ikke korrekt", color:"red"})
                    break;
                case 15:
                    setResponse({message: "Der er en fejl i opsamlingsdatoen", color:"red"})
                    break;
                case 16:
                    setResponse({message: "Der er for sent at bestille til den angivne dato ellers er leveringsdatoen før opsamlingsdatoen", color:"red"})
                    break
                case 17:
                    setResponse({message: "Adressen kunne ikke valideres, tjek at adressen er korrekt. Ofte skyldes " +
                            "fejlen enten stavefejl i vejnavnet, indastning af husnummer i både vej og nummer felt eller at " +
                            "der er angivet navnet på leveringsstedet i stedet for vejnavnet feks. er Rigshospitalet angivet i stedet for Blegdamsvej", color:"red"})
                    break
                case false:
                    setResponse({message: "Der er en fejl i de indtastede værdier", color:"red"})
                    break;
                case true:
                    setResponse({message: "Blomsten er blevet bestilt", color:"green"})
                    setStreet('')
                    setBuildingNumber('')
                    setZip('')
                    setCity('')
                    setChurch(0)
                    setChurchName('')
                    setChurchDeliveryH('')
                    setChurchDeliveryM('')
                    setSpecial(1)
                    setPickedUpFromShop(true)
                    setReceiver('')
                    setReceiverPhone('')
                    setFlowerCount('1')
                    break;
            }
        })
   }

   const getLatestDelivery = () => {
       Axios.post("https://"+ipaddress+"/getLatestDeliveryTime", {
           shop: shop ? shop : Authorization.authdetails.referencinguser,
       },{
           headers: {
               Authorization: Authorization.authdetails.authkey
           }
       }).then(res => {console.log(res.data.endTime); if(res.data !== null && res.data !== undefined && res.data.endTime !== undefined
           && res.data.endTime.length > 3) setLatestDeliveryTime(res.data.endTime.substr(0, 5))}).catch(err => console.log(err))
   }
    const renderChurchName = () => {
        if(church === 0) return null;
        return(
            <View>
                <View style={localStyle.inputLine}>
                    <Text>Kirke navn*: </Text>
                    <TextInput value={churchName} onChangeText={setChurchName} style={localStyle.inputField} maxLength={30}/>
                </View>
                <View style={localStyle.inputLine}>
                    <Text>Ceremoni starttidspunkt*:</Text>
                    <View style={{flexDirection: "row"}}>
                        <TextInput value={churchDeliveryH} placeholder={"TT"} onChangeText={setChurchDeliveryH} style={localStyle.dateInput} maxLength={2}/>
                        <Text>:</Text>
                        <TextInput value={churchDeliveryM} placeholder={"MM"} onChangeText={setChurchDeliveryM} style={localStyle.dateInput} maxLength={2}/>
                    </View>
                </View>
                <View style={[localStyle.choiceField]}>
                    <Text>Hvor skal blomsten samles op?</Text>
                    <View>
                    <Button
                        color={pickedUpFromShop ? "":"#A6D4F3"}
                        title= "Butikken"
                        onPress={() => setPickedUpFromShop(true)}
                    />
                    <Button
                        color={!pickedUpFromShop ?  "":"#A6D4F3"}
                        title="Torvet"
                        onPress={() => setPickedUpFromShop(false)}
                    />
                    </View>
                </View>
            </View>
        )
    }

    const renderSpecialMessage = () => {
        if (special === 1) return null;
        return (
            <TextInput
                multiline
                numberOfLines={5}
                maxLength={200}
                value={specialDesc}
                onChangeText={setSpecialDesc}
                placeholder={"Beskrivelse af leveringen..."}
                style={localStyle.inputField}
            />
        )

    }

    return(
        <ScrollView nestedScrollEnabled={true} contentContainerStyle={{width: "100%", alignItems: "center", justifyContent:"center"}}>
            <View style= {localStyle.subContainer}>

                {
                    (isAdminOrDriver()) ?
                    <DropDownPicker
                        open={open}
                        value={shop}
                        items={shopList}
                        setOpen={setOpen}
                        setValue={setShop}
                        setItems={setShopList}
                    />
                    :null
                }
                <View style={[localStyle.choiceField]}>
                    <Text>Vælg leveringstype: </Text>
                    <Button
                        color={church === 0 ? "":"#A6D4F3"}
                        title="Almindelig"
                        onPress={() => setChurch(0)}
                    />
                    <Button
                        color={church === 1 ?  "":"#A6D4F3"}
                        title="Kirke"
                        onPress={() => setChurch(1)}
                    />
                </View>
                {renderChurchName()}
                <DropdownTextInput callback={value => {
                    const terms = value.terms;
                    setStreet(terms[0].value)
                    let cityTerm = "";
                    if(terms[1].value.match(/^[0-9]+$/) == null){
                        cityTerm = terms[1].value
                    } else {
                        setBuildingNumber(terms[1].value)
                        cityTerm = terms[2].value
                    }
                    numberRef.current.focus()
                    setCity(cityTerm)
                    Axios.post("https://"+ipaddress+"/getZipFromCity", {
                        city: cityTerm
                    }).then(r => {
                        setZip(r.data.toString())
                    }).catch(err => console.log(err))
                }}/>
                    <View style={localStyle.inputLine}>
                        <Text>Vejnavn*: </Text>
                        <TextInput value={street} onChangeText={setStreet} style={localStyle.inputField} maxLength={30}/>
                    </View>
                    <View style={localStyle.inputLine}>
                        <Text>Nummer og lejlighed*: </Text>
                        <TextInput ref={numberRef} value={buildingNumber} onChangeText={setBuildingNumber} style={localStyle.inputField} maxLength={20}/>
                    </View>
                    <View style={localStyle.inputLine}>
                        <Text>Post nr.*: </Text>
                        <TextInput value={zip} onChangeText={text => {setZip(text); if(text.length === 4){
                            Axios.post("https://"+ipaddress+"/getCityFromZip", {
                                zip: text
                            }).then(r => {
                                console.log(r.data, "City")
                                setCity(r.data.toString())
                                nextFromZip.current.focus()
                            }).catch(err => {
                                nextFromZip.current.focus()
                                console.log(err)
                            })

                        } }} style={localStyle.inputField} maxLength={4}/>
                    </View>
                    <View style={localStyle.inputLine}>
                        <Text>By*: </Text>
                        <TextInput ref={nextFromZip} value={city} onChangeText={setCity} style={localStyle.inputField} maxLength={30}/>
                    </View>
                    <View style={localStyle.inputLine}>
                        <Text>Modtager: </Text>
                        <TextInput value={receiver} onChangeText={setReceiver} style={localStyle.inputField} maxLength={30}/>
                    </View>
                    <View style={localStyle.inputLine}>
                        <Text>Modtager tlf: </Text>
                        <TextInput value={receiverPhone}
                                   onChangeText={text => {setReceiverPhone(text); if(text.length === 8) nextFromReceiverPhone.current.focus()}}
                                   style={localStyle.inputField} maxLength={8}/>
                    </View>
                    <View>
                        <Text>Antal blomster*: </Text>
                        <TextInput ref={nextFromReceiverPhone} value={flowerCount} onChangeText={setFlowerCount} style={localStyle.inputField} maxLength={3}/>
                    </View>
                    <View style={localStyle.inputLine}>
                        <Text>Opsamlings dato*:</Text>
                        <DatePicker source={pickUpDate} setSource={setPickUpDate} style={localStyle.dateInput}/>
                    </View>
                    <View style={localStyle.inputLine}>
                        <Text>Leverings dato*:</Text>
                        <View style={{flexDirection: "row"}}>
                            <TextInput value={day}
                                       onChangeText={text => {setDay(text); if(text.length === 2) nextFromDay.current.focus()}}
                                       style={localStyle.dateInput} maxLength={2} placeholder={"DD"}/>
                            <Text>/</Text>
                            <TextInput ref={nextFromDay} value={month}
                                       onChangeText={text => {setMonth(text);}}
                                       style={localStyle.dateInput} maxLength={2} placeholder={"MM"}/>
                            <Text>/</Text>
                            <TextInput value={year} onChangeText={text => {setYear(text);}}
                                       style={localStyle.dateInput} maxLength={2} placeholder={"YY"}/>
                        </View>
                    </View>

                    <View style={[localStyle.choiceField]}>
                        <Text>Special levering </Text>
                        <Button
                            color={special === 0 ? "":"#A6D4F3"}
                            title="Ja"
                            onPress={() => setSpecial(0)}
                        />
                        <Button
                            color={special === 1 ?  "":"#A6D4F3"}
                            title="Nej"
                            onPress={() => setSpecial(1)}
                        />
                    </View>
                    {renderSpecialMessage()}
                    {(special === 0 || church === 1) ? <Text style={{flexWrap: "wrap", flexDirection:"row"}}>Bemærk den viste pris er standardprisen afvigelser kan forekomme</Text> : null}
                    {price !== '' ? <Text  style={localStyle.priceTicker}>Pris: {price} kr.</Text> : null}
                    <Text style={{color: response.color}}>{response.message}</Text>
                    <Text>Seneste bestillingstid: {latestDeliveryTime}</Text>
                    <Button title={"Bestil levering"} onPress={orderFlower}/>
            </View>
        </ScrollView>
    )
}

const localStyle = StyleSheet.create({
    choiceField: {
        flexDirection:'row',
        alignItems: 'center',
        justifyContent: "flex-start",
        maxWidth: "95%"
    },
    inputLine: {
        flexDirection: "row",
        marginVertical: 4,
        justifyContent: "space-between"
    },
    dateInput: {
        width: 25,
        borderWidth: 1,
        borderRadius: 5
    },
    inputField: {
        borderWidth: 1,
        borderRadius: 5,
        minWidth: 100
    },
    subContainer: {
        paddingVertical: 4,
        width: Platform.OS === "web" ? "20%" : "95%"
    },
    priceTicker: {
        fontSize: 16,
        marginVertical: 3
    }

})
