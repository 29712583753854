import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View } from 'react-native';

export default function Header(){
    return(
        <View style={styles.container}>
            <Text style={styles.text}>Københavns Blomstertransport</Text>
        </View>
    )
}

const styles = StyleSheet.create({
    container:{
        paddingTop: 38,
        height: 80,
        backgroundColor: 'coral',
        alignItems: 'center'
    },
    text: {
        color: 'white',
        fontWeight: 'bold',
        fontSize: 20
    }
})