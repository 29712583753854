import React, {useEffect, useState} from 'react';
import {StyleSheet, View, Text, Button, TextInput, Platform, TouchableOpacity, FlatList} from 'react-native';
import Axios from 'axios';
import styles from '../../globalStyles/userTypeFrontPageStyle';
import DatePicker from "../../components/datePicker";
import {ipaddress} from "../../backend/backendConnection";
import Authorization from "../../backend/authorization";
import {MaterialIcons} from "@expo/vector-icons";
import {changeDeliveryState, countFlowers} from "../../backend/datafunctions";

export default function AfternoonRoutePage({navigation}){
    const [flowers, setFlowers] = useState([])
    const d = new Date();
    const [date, setDate] = useState({day: d.getDate(), month: d.getMonth() + 1, year: d.getFullYear()})
    const loadFlowers = () => {
        Axios.post("https://"+ipaddress+"/getFlowersOnAfternoonRoute", {
                driver: Authorization.authdetails.referencinguser,
                date: date
            },
            {
                headers: {
                    Authorization: Authorization.authdetails.authkey
                }
            }).then(r=> {console.log(r.data);setFlowers(r.data)})
    }


    useEffect(() => {
        loadFlowers()
    }, [date])

    const UndeliveredHeader = () => {
        return(
            <View style={[localStyle.entry, {backgroundColor: "white"}]}>
                <Text style={localStyle.addressColumn}>Adresse:</Text>
                <Text style={localStyle.shopCollectorColumn}>Butik:</Text>
                <Text style={localStyle.shopCollectorColumn}>Opsamler:</Text>
                <Text style={localStyle.smallColumn}>Lev:</Text>
            </View>
        )
    }

    const RenderUndeliveredFlower = ({item}) => {
        console.log(item)
        const address = item.street+", "+item.number+","+item.city+", "+item.zip + (item.afternoonChurchDelivery === 1 ? ", "+ item.churchName : "")
        let delButton = null;
        let newState = 'H';
        switch (item.delivered){
            case 'H':
                delButton = 'delivery-dining'
                newState = 'P'
               break
            case 'R':
                delButton = 'arrow-back'
                newState = 'H'
                break
            case 'P':
                delButton = 'person';
                newState = 'D'
                break
            case 'D':
                delButton = 'sensor-door';
                newState = 'R'
                break
        }
        return(
            <View style={localStyle.entry}>
                <Text style={localStyle.addressColumn}>{address} {item.count > 1 ? "*" : ""}</Text>
                <Text style={localStyle.shopCollectorColumn}>{item.shop}</Text>
                <Text style={localStyle.shopCollectorColumn}>{item.collectorDriver}</Text>
                <TouchableOpacity style={localStyle.smallColumn} onPress={() => changeDeliveryState(item, newState, loadFlowers)}>
                    <MaterialIcons name = {delButton} size={30}/>
                </TouchableOpacity>
            </View>
        )
    }
    return(
        <View style={localStyle.container}>
            <View style={{alignItems: "center", maxHeight: "90%"}}>
            <Text style={localStyle.headerText}>Eftermiddag</Text>
            <DatePicker source={date} setSource={setDate}/>
            <FlatList data={flowers}
                      ListHeaderComponent={<UndeliveredHeader/>}
                      renderItem={({item}) => <RenderUndeliveredFlower item={item}/>}/>
            </View>
            <Text>Blomster: {countFlowers(flowers)}</Text>
            <Button title={"Planlæg ruten"} onPress={() => navigation.navigate('AfternoonNavigationPage', {
                date: date
            })}/>
        </View>
    )
}

const localStyle = StyleSheet.create({
    container: {
        alignItems: "center"
    },
    headerText: {
        fontSize: 20
    },
    addressColumn: {
        width:"30%"
    },
    shopCollectorColumn:{
        width: "30%"
    },
    smallColumn: {
        width:"10%"
    },
    entry:{
        width:"100%",
        flexDirection: "row",
        borderBottomWidth: 1
    }
})
