import React, {Component, useEffect, useState} from "react";
import {View, FlatList, Text, TextInput, Button, StyleSheet, TouchableOpacity, Alert, Platform} from "react-native";
import styles from '../../globalStyles/userTypeFrontPageStyle';
import Axios from "axios";
import {MaterialIcons} from '@expo/vector-icons';
import {AntDesign} from '@expo/vector-icons';
import {ipaddress} from "../../backend/backendConnection";
import DeleteButton from "../../components/deleteButton";
import {SendOutlined} from "@ant-design/icons";
import Authorization from "../../backend/authorization";

export default function ShopOverview(){

    const [shops, setShops] = useState([])
    const [oldActiveEntryName, setOldActiveEntryName] = useState("");
    const [activeEntryName, setActiveEntryName] = useState("");
    const [activeEntryStreet, setActiveEntryStreet] = useState("");
    const [activeEntryNumber, setActiveEntryNumber] = useState("");
    const [activeEntryCity, setActiveEntryCity] = useState("");
    const [activeEntryZip, setActiveEntryZip] = useState("");
    const [activeEntryPhone, setActiveEntryPhone] = useState("");
    const [activeEntryEmail, steActiveEntryEmail] = useState("");
    const [rejectPressed, setRejectPressed] = useState(false);

    const renderModifyShopComponent = () => {
        if(activeEntryName !== "")
        return(
            <View style={{width: "100%"}}>
                <Text>Opdater stamdata for butikker</Text>
                <View style={localStyle.modificationLine}>
                    <Text>Butiksnavn: </Text>
                    <TextInput style={localStyle.editInputField} value={activeEntryName} onChangeText={setActiveEntryName}/>
                </View>
                <View style={localStyle.modificationLine}>
                    <Text>Email: </Text>
                    <TextInput style={localStyle.editInputField} value={activeEntryEmail} onChangeText={steActiveEntryEmail}/>
                </View>
                <View style={localStyle.modificationLine}>
                    <Text>Gade: </Text>
                    <TextInput style={localStyle.editInputField} value={activeEntryStreet} onChangeText={setActiveEntryStreet}/>
                </View>
                <View style={localStyle.modificationLine}>
                    <Text>Bygningsnummer: </Text>
                    <TextInput style={localStyle.editInputField} value={activeEntryNumber} onChangeText={setActiveEntryNumber}/>
                </View>
                <View style={localStyle.modificationLine}>
                    <Text>By: </Text>
                    <TextInput style={localStyle.editInputField} value={activeEntryCity} onChangeText={setActiveEntryCity}/>
                </View>
                <View style={localStyle.modificationLine}>
                    <Text>Postnummer: </Text>
                    <TextInput style={localStyle.editInputField} value={activeEntryZip} onChangeText={setActiveEntryZip}/>
                </View>
                <View style={localStyle.modificationLine}>
                    <Text>Tlf. nr.: </Text>
                    <TextInput style={localStyle.editInputField} value={activeEntryPhone} onChangeText={setActiveEntryPhone}/>
                </View>
                <Button title="Opdater data" onPress={() => updateShop()}/>
            </View>
        )
    }
    const getShops = () => {
        Axios.get("https://"+ipaddress+"/shops").then((response) => {
            let data = []
            for(const e of response.data){
                data.push({...e, zip: e.zip.toString()})
            }
            setShops(data)
        })
    }

    useEffect(() => {
        getShops()
    }, [])

    const updateShop = () => {
        Axios.post("https://"+ipaddress+"/updateShop/"+oldActiveEntryName+"/"+activeEntryName+"/"+activeEntryStreet+"/"+activeEntryNumber+"/"+activeEntryCity+"/"+activeEntryZip+"/"+activeEntryPhone+"/"+activeEntryEmail).then(response => {
            if(response){
                setOldActiveEntryName(activeEntryName)
            }
        })
    }


    const approveHandler = ({item}) => {
        Axios.post("https://"+ipaddress+"/approveShop/"+item.name).then(r => getShops())
    }

    const rejectHandler = ({item}) => {
        Axios.delete("https://" + ipaddress + "/rejectShop/" + item.name).then(r => getShops())
    }

    const deactivationHandler = (item) => {
        Axios.post("https://"+ipaddress+"/deactivateShop/"+item.name).then(r => getShops())
    }

    const renderUnapprovedShop = ({item}) =>{
        return(
            <View style={localStyle.unapprovedShopEntry}>
                <Text>{item.name}</Text>
                <View style={localStyle.entryButtons}>
                    <Button title={"Godkend"} onPress={() => approveHandler({item})}/>
                    {
                        rejectPressed ?
                            <View style={{flexDirection: "row"}}>
                                <Button title={"Slet"} onPress={() => {setRejectPressed(false); rejectHandler({item})}}/>
                                <Button title={"Tilbage"} onPress={() => {setRejectPressed(false)}}/>
                            </View>
                        :
                        <Button title={"Afvis"} onPress={() => setRejectPressed(true)}/>
                    }

                </View>
            </View>
        )
    }

    const editHandler = ({item}) => {
        setOldActiveEntryName(item.name);
        setActiveEntryName(item.name);
        setActiveEntryStreet(item.street);
        setActiveEntryNumber(item.number);
        setActiveEntryCity(item.city);
        setActiveEntryZip(item.zip);
        setActiveEntryPhone(item.phoneNumber);
        steActiveEntryEmail(item.Email);
    }

    const sendInvoice = (shop) => {
        Axios.post("https://"+ipaddress+"/sendInvoice", {
            shop: shop
        },{
            headers: {
                Authorization: Authorization.authdetails.authkey
            }
        }).catch(err => console.log(err))
    }

    const renderApprovedShop = ({item}) => {
        return(
            <View style={localStyle.shopEntry}>
                <Text>{item.name}</Text>
                <View style={localStyle.entryButtons}>
                    <TouchableOpacity onPress={() => {
                        if(Platform.OS !== "web") {
                            Alert.alert(
                                "Advarel!",
                                "Vil du gerne sende en faktura",
                                [
                                    {
                                        text: "Ja",
                                        onPress: () => sendInvoice(item),
                                    },
                                    {
                                        text: "Nej"
                                    }
                                ]
                            )
                        } else {
                            sendInvoice(item)
                        }
                    }}>
                        <MaterialIcons name='attach-money' size={30}/>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => editHandler({item})}>
                        <AntDesign name='edit' size={30}/>
                    </TouchableOpacity>
                    <DeleteButton deleteFunction={deactivationHandler} item={item}/>
                </View>
            </View>
        )
    }

    const renderAwaitingComponent = () => {
        if(shops.filter(entry => entry.accepted ===0).length !== 0){
            return(
                <View style={localStyle.awaitingApprovalArea}>
                    <Text style={localStyle.titleText}>Butikker der afventer godkendelse</Text>
                    <FlatList style={{width:"100%"}} data={shops.filter(entry => entry.accepted === 0)} renderItem={({item}) => renderUnapprovedShop({item})}/>
                </View>
            )
        }
    }



    return(
        <View style={{alignItems: "center"}}>
            <View style={{alignItems: "center", width: Platform.OS === "web" ? "40%" : "100%"}}>
                    <Text style={localStyle.titleText}>Aktive butikker</Text>
                     <FlatList style={{width: "100%", height: "50%"}} data={shops.filter(entry => entry.accepted === 1)} renderItem={({item}) => renderApprovedShop({item})}/>
                    {renderModifyShopComponent()}
                    {renderAwaitingComponent()}
            </View>
        </View>
    )

}

const localStyle = StyleSheet.create({
    titleText:{
        fontSize:30,
        backgroundColor: "coral",
        textAlign: "center",
        width: "100%"
    },
    awaitingApprovalArea: {
        alignContent:"flex-start",
        width: "100%"
    },
    shopEntry: {
        alignItems: "center",
        flexDirection: "row",
        marginVertical: 3,
        justifyContent:"space-between",

    },
    unapprovedShopEntry: {
        flexDirection: "row",
        marginVertical: 3,
        justifyContent:"space-between",
        width: "100%"

    },
    entryButtons: {
        flexDirection: "row",
        justifyContent: "space-between"
    },
    modificationLine:{
        flexDirection: "row",
        padding: 2,
        justifyContent: "space-between",
        width: "100%"
    },
    editInputField: {
        borderWidth: 1,
        borderRadius: 5,
        width: "40%"
    }
})
