import React, {Component, useEffect, useState} from "react";
import {View, FlatList, Text, TextInput, Button, StyleSheet, TouchableOpacity, Alert, Platform} from "react-native";
import styles from '../../globalStyles/userTypeFrontPageStyle';
import Axios from "axios";
import NameEntry from "./DriverNameEntry";
import {ipaddress} from "../../backend/backendConnection";
import Authorization from "../../backend/authorization";
import CryptoJS from "crypto-js";
export default function DriverOverview(){

    const [driverData, setDriverData] = useState([]);
    const [password, setPassword] = useState('')
    const [password2, setPassword2] = useState('')
    const [username, setUsername] = useState('')
    const getDriverData = () => {
        Axios.get("https://"+ipaddress+"/drivers").then((response) => {
            setDriverData(response.data);
        });
    }

    const [succesText, setSuccesText] = useState("");
    const [driverName, setDriverName] = useState("");
    useEffect(() =>{
        getDriverData();
    },[succesText])

    const addDriver = () => {
        if(password.length < 1 || password !== password2) {
            setSuccesText("De indtastede passwords er ikke ens")
            return;
        }
        if(driverName !== ""){
            Axios.post("https://"+ipaddress+"/createDriver", {
                name: driverName,
                password: CryptoJS.SHA1(password).toString(),
                username: username
            }, {
                    headers: {
                        Authorization: Authorization.authdetails.authkey
                    }
                }).then(response => {
                if(response){
                    setSuccesText("Chaufføren er tilføjet")
                    setDriverName("")
                }else{
                    setSuccesText("Chaufføren er ikke tilføjet der er sket en fejl")
                }
            })
        }
    }

    const pressHandler = (driverID) => {
        Axios.post("https://"+ipaddress+"/deleteDriver", {
            id: driverID
        }).then(() => getDriverData())
    }

    return(
        <View style={{width: Platform.OS === "web" ? null : "100%", justifyContent:"center", alignItems: "center", }}>
            <FlatList data={driverData} renderItem={({item}) => (<NameEntry data={item} pressHandler={pressHandler}/>)}/>
            <View style={localStyle.AddDriverContainer}>
                <Text>Tilføj en ny chauffør</Text>
                <Text>Indtast chaufførens navn:</Text>
                <TextInput style={localStyle.TextInput} onChangeText={setDriverName} value={driverName}/>
                <Text>Indtast brugernavn:</Text>
                <TextInput style={localStyle.TextInput} onChangeText={setUsername} value={username}/>
                <Text>Indtast kodeord:</Text>
                <TextInput style={localStyle.TextInput} onChangeText={setPassword} secureTextEntry={true} value={password}/>
                <Text>Indtast kodeord:</Text>
                <TextInput style={localStyle.TextInput} onChangeText={setPassword2} secureTextEntry={true} value={password2}/>
                <Button title="Tilføj" style={localStyle.SubmitButton} onPress={() => addDriver()}/>
                <Text>{succesText}</Text>
            </View>
        </View>

    )
}

const localStyle = StyleSheet.create({
    TextInput: {
        borderWidth: 1
    },
    SubmitButton: {
        width: "100%"
    },
    AddDriverContainer: {
        margin: 10,
        width: 300,
    },
    nameLine:{
        margin:5,
        flexDirection: 'row'
    }
})
/*        const confirmDeletion = () =>{
            Alert.Alert("Vil du slette denne chauffør?", "Hvis du sletter chaufføren forsvinder alt data relateret til chaufføren",
            [
                {
                    text: "Slet",
                    onPress: ()=>{
                        Axios.post("https://"+ipaddress+"/deleteDriver", {
                            name: "Ranger"
                        })
                    }
                },
                {
                    text:"Behold"
                }
            ])
    }*/
