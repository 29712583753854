import React, {Component, useEffect, useState} from "react";
import {
    View,
    FlatList,
    Text,
    TextInput,
    Button,
    StyleSheet,
    TouchableOpacity,
    BackHandler,
    SectionList
} from "react-native";
import styles from '../../globalStyles/userTypeFrontPageStyle';
import Axios from "axios";
import Authorization from "../../backend/authorization";
import DatePicker from "../../components/datePicker";
import {MaterialIcons} from '@expo/vector-icons';
import {AntDesign} from '@expo/vector-icons';
import {ipaddress} from "../../backend/backendConnection";

export default function SquareGoodsPage(){
    const d = new Date()
    const [date, setDate] = useState({day: d.getDate(), month: d.getMonth()+1, year: d.getFullYear()})
    const [response, setResponse] = useState({message: '', color: 'red'})
    const [orders, setOrders] = useState([])
    const [deleteResponse, setDeleteResponse] = useState("")

    useEffect(() => {
        Axios.post("https://"+ipaddress+"/getSquareGoods", {
            shop: Authorization.authdetails.referencinguser,
            date: date
        }, {
            headers: {
                Authorization: Authorization.authdetails.authkey
            }
        }).then(res => {console.log(res.data);setOrders(res.data)})
    }, [response, deleteResponse, date])
    const orderGoods = () => {
        Axios.post("https://"+ipaddress+"/orderSquareGoods", {
            shop: Authorization.authdetails.referencinguser,
            date: date
        }, {
            headers: {
                Authorization: Authorization.authdetails.authkey
            }
        }).then(res => {
            console.log(res.data)
            switch (res.data){
                case 10:
                    setResponse({message: "Der er sket en fejl prøv at genstarte applikationen", color:"red"})
                    break;
                case 13:
                    setResponse({message: "Der er noget galt med den indtastede dato", color:"red"})
                    break;
                case 14:
                    setResponse({message: "Der er allerede bestilt torvevarer", color:"red"})
                    break;
                case true:
                    setResponse({message: "Torvevarer er blevet bestilt", color:"green"})
                    break;
            }
        }).catch(err => {
            console.log(err)
        })
    }


    const deleteOrder = (date) => {
        Axios.post("https://"+ipaddress+"/deleteSquareGoodOrder", {
            shop: Authorization.authdetails.referencinguser,
            date: date
        }, {
            headers: {
                Authorization: Authorization.authdetails.authkey
            }
        }).then(r => {setDeleteResponse("Torvevarer d. "+date+" er blevet afbestilt")})
    }

    const OrderDate = ({date}) => {
        const da = date.date;
        const dd = new Date(da)
        const now = new Date()
        const changeTimeZone = (date, timeZone) => {
            if (typeof date === 'string') {
                return new Date(
                    new Date(date).toLocaleString('en-US', {
                        timeZone,
                    }),
                );
            }

            return new Date(
                date.toLocaleString('en-US', {
                    timeZone,
                }),
            );
        }

        const current = changeTimeZone(new Date(), "Europe/Copenhagen")
        const checkTime = new Date(date.date.substr(0,4)+"-"+date.date.substr(5,2)+"-"+date.date.substr(8,2))
        console.log(date.date.substr(0,4)+"-"+date.date.substr(5,2)+"-"+date.date.substr(8,2))
        checkTime.setHours(8,30)
        return(
            <View style={{flexDirection: "row", borderWidth: 2, margin:5}}>
                <Text>{da.substr(8,2)}/{da.substr(5,2)}/{da.substr(0,4)}, {date.price} kr.</Text>
                {
                    current > checkTime ? null :
                    <TouchableOpacity onPress={() => deleteOrder(da)}>
                        <MaterialIcons name='delete' size={30}/>
                    </TouchableOpacity>
                }
            </View>
        )
    }
    return(
        <View style={styles.container}>
            <Text style={localStyle.header}>Bestil torvevarer</Text>
            <View style={{flexDirection: "row", marginBottom: 10}}>
                <Text>Dato: </Text>
                <DatePicker
                    source={date}
                    setSource={setDate}
                    />
            </View>
            <Button title={"Bestil"} onPress={orderGoods}/>

            <Text style={{color: response.color}}>{response.message}</Text>
            <Text style={{fontSize: 15}}>Bestilte varer:</Text>
            <Text>{deleteResponse}</Text>
            <FlatList data={orders} renderItem={({item}) => <OrderDate date={item}/>}/>
        </View>
    )
}

const localStyle = StyleSheet.create({
    header: {
        fontSize: 30
    }
})
