import React, { useEffect, useState } from "react";
import {View, Button, Text, StyleSheet, FlatList, ScrollView, Platform} from "react-native";
import styles from '../../globalStyles/userTypeFrontPageStyle';
import Axios from "axios";
import {MaterialIcons} from '@expo/vector-icons';
import { TouchableOpacity } from "react-native";
import {ipaddress} from "../../backend/backendConnection";


export default function DriversWorkingCalendar({pressHandler, year, week}){

    const [daysConfig, setDaysConfig] = useState([])
    const [days, setDays] = useState([0,1,2,3,4,5,6])

    const getDriversWorking = () => {
        Axios.get("https://"+ipaddress+"/driversWorking/"+ year +"/" + week).then((response) => {
            setDaysConfig(response.data);
        });
    }
    useEffect(() =>{
        getDriversWorking()
    }, [week])

    const deleteDriverWorking = ({item, day, sec}) => {
        console.log(item)
        Axios.delete("https://"+ipaddress+"/deleteDriversWorking/"+item.DriverID+"/"+year+"/"+week+"/"+day+"/"+sec).then(() => getDriversWorking()).catch(err => console.log(err))
    }

    const deleteDriverWorkingApp = (entry) => {
        console.log(entry)
        Axios.delete("https://"+ipaddress+"/deleteDriversWorking/"+entry.DriverID+"/"+year+"/"+week+"/"+entry.day+"/"+entry.section).then(() => getDriversWorking()).catch(err => console.log(err))
    }

    const renderItem = ({item, day, sec}) =>{
        return(
            <View style={{flexDirection: "row"}}>
                <Text>{item.DriverName}</Text>
                <TouchableOpacity onPress={() => deleteDriverWorking({item, day, sec})}>
                    <MaterialIcons name='delete' size={20} />
                </TouchableOpacity>
            </View>
            )
    }
    const renderWeb = () => {
        return (<View style={styles.container}>
            <Text style={localStyle.calendarTitle}>Plan</Text>
            <View style={localStyle.weekContainer}>
                {
                    days.map((day) => {

                        console.log("rendered")
                            const dayNames = ["Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag", "Lørdag", "Søndag"]

                            let drivers = daysConfig.filter(entry => entry.day === day)

                            return (
                                <View style={localStyle.dayContainer}>

                                    <Text>{dayNames[day]}</Text>
                                    <Text>Formiddag</Text>
                                    <TouchableOpacity onPress={() => pressHandler(day, 1, getDriversWorking)}>
                                        <ScrollView style={localStyle.timeSlot} keyboardShouldPersistTaps={'always'}>
                                            <FlatList data={drivers.filter(entry => entry.section === 1)}
                                                      keyExtractor={(item) => {
                                                          return item.DriverID.toString() + day.toString() + "1"
                                                      }}
                                                      renderItem={({item}) => {
                                                          const sec = 1;
                                                          return (renderItem({item, day, sec}))
                                                      }}/>
                                        </ScrollView>
                                    </TouchableOpacity>
                                    <TouchableOpacity onPress={() => pressHandler(day, 2, getDriversWorking)}>
                                        <Text>Eftermiddag</Text>
                                        <ScrollView style={localStyle.timeSlot} keyboardShouldPersistTaps='always'>
                                            <FlatList data={drivers.filter(entry => entry.section === 2)}
                                                      keyExtractor={(item) => {
                                                          return item.DriverID.toString() + day.toString() + "2"
                                                      }}
                                                      renderItem={({item}) => {
                                                          const sec = 2;
                                                          return (renderItem({item, day, sec}))
                                                      }}/>
                                        </ScrollView>
                                    </TouchableOpacity>
                                </View>
                            )
                        }
                    )
                }
            </View>
        </View>)
    }

    const renderDay = (day) => {
        const driversOnMorning = daysConfig.filter(e => e.day === day && e.section === 1)
        const driversOnAfternoon = daysConfig.filter(e => e.day === day && e.section === 2)
        //console.log(driversOnMorning, driversOnAfternoon)
        const d = (18+day);
        const date = new Date("2022-07-"+d);
        let days = ['Søndag', 'Mandag', 'Tirsdag', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        return(
            <View style={localStyle.dayEntry}>
                <Text>{days[date.getDay()]}</Text>
                <Text>Morgen</Text>
                <TouchableOpacity style={localStyle.AppDayView} onPress={() => pressHandler(day, 1, getDriversWorking)}>
                    {driversOnMorning.map((e) => {
                        console.log(e)
                        return(
                            <View style={{width:"100%", flexDirection: "row", alignItems: "space-between"}}>
                                <Text>{e.DriverName}</Text>
                                <TouchableOpacity onPress={() => deleteDriverWorkingApp(e)}>
                                    <MaterialIcons name='delete' size={20} />
                                </TouchableOpacity>
                            </View>
                            )
                    })}
                </TouchableOpacity>
                <Text>Eftermiddag</Text>
                <TouchableOpacity style={localStyle.AppDayView} onPress={() => pressHandler(day, 2, getDriversWorking)}>
                    {driversOnAfternoon.map((e) => {

                        return(
                            <View style={{width:"100%", flexDirection: "row", alignItems: "space-between"}}>
                                <Text>{e.DriverName}</Text>
                                <TouchableOpacity onPress={() => deleteDriverWorkingApp(e)}>
                                    <MaterialIcons name='delete' size={20} />
                                </TouchableOpacity>
                            </View>
                        )
                    })}
                </TouchableOpacity>
            </View>
        )
    }

    const renderPhone = () => {
        return(
            <View style={localStyle.container}>
                {days.map((day) =>  renderDay(day))}
            </View>
        )
    }


    return (
            Platform.OS === "web" ?
            renderWeb(): renderPhone()

    )

}

const localStyle = StyleSheet.create({
    calendarTitle:{
        fontSize: 48,
        justifyContent: "center"
    },
    weekContainer:{
        alignItems: "center",
        flexDirection: Platform.OS === "web" ? "row" : "column"
    },
    dayContainer: {
        flexDirection: "column",
        alignItems: "center",
        marginHorizontal: 5
    },
    timeSlot:{
        height: Platform.OS === "web" ? 200 : 50,
        minWidth: 70,
        borderWidth: 1
    },
    entryStyle:{
        flexDirection: "row"
    },
    container: {
        alignItems: "center",
        width: "90%"
    },
    dayEntry: {
        alignItems: "center",
        width:"60%",
    },
    AppDayView: {
        borderWidth:1,
        minHeight: 50,
        width: "100%",
        padding: 2,
        borderRadius: 3
    }
})

/*


                    })*/
